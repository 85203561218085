/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from "../../environments/environment";
import { Broadcaster } from "../shared/providers/broadcaster.service";
import { BehaviorSubject, map, Observable, tap, timer } from "rxjs";
import { ProfileResponse } from "../../responses/profile.response";
import { distinctUntilChanged, filter, Subject, take, debounceTime } from "rxjs";
import { SetXmppConnection, XmppSession, SetBare, SetFirebaseToken, XmppOMEMOInit, XmppOMEMOMaxDevicesReached } from "../../actions/app";
import { TalkRootState, getConversationById, getConversationMembers, getMessageById, getUnArchivedConversations, getProcessingE2EMessages } from "../reducers";
import { ConversationBlockListIndex, ConversationSubjectUpdate, ConversationCreate,
  ConversationResetAllActivated, ConversationsBlockListAdd, ConversationsBlockListRemove } from "../actions/conversation";
import { Message } from "../models/message.model";
import {
  getIsConnectedXMPP,
  getUserProfile,
  getUserStatus,
  getContactById,
  getAppSettings,
  getNetworkInformation,
  getIsLoggedIn,
  getContactStatusById,
  getXmppOmemoInitState
} from "../../reducers";
import { JID, VCard } from "../models/jid.model";
import { ConversationConfig } from "../models/conversation.model";
import { Presence } from "../models/presence.model";
import { getUserStatusType } from "../../shared/models/index";
import { UploadSlot } from "../models/index";
import { CommonUtil } from "../utils/common.util";
import { ConversationUtil } from "../utils/conversation.util";
import { ConstantsUtil } from "../utils/constants.util";
import * as punycode from "punycode/punycode";
import { ContactInformation } from "../models/vcard.model";
import { ContactAddVCard, ContactBulkNicknameUpdate, ContactBulkAppStatusUpdate } from "../../actions/contact";
import { MessageUtil, } from "../utils/message.util";
import { AuthService } from "app/shared/services/auth.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { NotificationService } from "./notification.service";
import { DatetimeService } from "./datetime.service";
import { XmppServiceOmemo } from "./xmpp.service.omemo";
import { DatabaseService } from "./db/database.service";
import { normalizeCommonJSImport } from "../utils/normalize-common-js-import";
import { LoggerService } from "app/shared/services/logger.service";
import { TranslateService } from "@ngx-translate/core";
import { AddProcessingMessages, MessageUpdateAction, RemoveProcessingMessage } from "../actions/message";
import { ConfigService } from "app/config.service";

const DECRYPT_ATTEMPT = 2;
@Injectable()
export class XmppService {
  public xmpp;

  private LastInactiveTime: any;
  private xmppLoggedOut = true;
  private isXmppConnected = false;

  private profile: ProfileResponse;
  private conferenceDomain: string;
  private nickname: string;
  private isLoggedIn: boolean;
  private networkOnline: boolean = true;
  private inBackground: boolean = false;

  private omemoService: XmppServiceOmemo;
  // Subjects
  private _priority = new BehaviorSubject<number>(0);
  private _onMessage = new Subject<Message>();
  private _onMamMessage = new Subject<Message>();
  private _onProcessOnResendSignal = new Subject<any>();
  private _onDeleteMessage = new Subject<string>();
  private _onMessageReceipt = new Subject<string>();
  private _onMucError = new Subject<any>();
  private _onPresence = new Subject<Presence>();
  // private _onMucAvailable = new Subject<MucAvailable>();
  private _onMucInvite = new Subject<any>(); // TODO: change any to Model
  private sentNickname: boolean;
  private _onOmemoTrigger = new Subject<any>();
  private _connectionStatus = new BehaviorSubject<XmppConnectionStatus>(XmppConnectionStatus.Disconnected);
  private _screenLockStatus = new BehaviorSubject<boolean>(false);
  networkSubscription$: any;
  sendReceipt: boolean;
  private _onStampReceived = new Subject<{jid: string, timestamp: number}>();
  private reconnectionTimer$: any;
  static XMPP_RECONNECTION_INTERVAL = 5000; // each 5 seconds

  private roomsJoinedTime = {};
  setupExtend: boolean;
  xmppLoaded$ = new BehaviorSubject<boolean>(false);
  rosterItemJids: string[] = [];
  processingE2EMessages = [];
  pendingMarkReadSignals = [];

  constructor(private store: Store<TalkRootState>,
              private broadcaster: Broadcaster,
              private authService: AuthService,
              private databaseService: DatabaseService,
              private datetimeService: DatetimeService,
              private zone: NgZone,
              private logger: LoggerService,
              private translate: TranslateService,
              private configService: ConfigService,
              private electronService: ElectronService,
              private notificationService: NotificationService) {
  }

  public init() {
    this.logger.info("[XmppService][init]");

    this.store.select(getIsLoggedIn).subscribe(v => {
      this.isLoggedIn = v;

      this.logger.info("[XmppService][init] isLoggedIn", v);
    });

    this.setupXmppConnectionStatusUpdate();
    this.setupXmppIsConnectedStateUpdate();

    const profile$ = this.store.select(getUserProfile);
    //
    profile$.pipe(filter(profile => !!profile)).subscribe(profile => {
      this.profile = profile;
      // this.logger.info("[XmppService] getUserProfile", JSON.stringify(profile));

      // set conf domain
      this.conferenceDomain = `conference.${this.profile.domain}`;
      localStorage.setItem("conferenceDomain", this.conferenceDomain);
      this.store.select(getProcessingE2EMessages).subscribe(v => {
        this.processingE2EMessages = v || [];
       });
      this.store.select(getIsLoggedIn).pipe(filter(v => !!v)).subscribe(v => {
        this.logger.info("[XmppService] getIsLoggedIn", v, this.xmpp);
        this.isLoggedIn = v;
        if (this.isLoggedIn && !this.xmpp) {
          this.setupXMPPConnection();

          this.setupNetworkChangesListener();
          this.xmppLoaded$.pipe(filter(v => !!v), take(1)).subscribe(() => {
            this.login();
          });
        }
      });
    });
    //
    profile$.pipe(filter(profile => !!profile), take(1)).subscribe(() => {
      const priority = +localStorage.getItem(this.priorityKey) || 8;
      this.setPriority(priority);
    });

    this.store.select(getAppSettings)
      .pipe(distinctUntilChanged())
      .subscribe(options => {
        this.sendReceipt = options.receipts;
    });
    if (this.electronService.ipcRenderer) {
      this.electronService.ipcRenderer.on("powerMonitor", (event, message) => {
        this.broadcaster.broadcast("powerMonitor", message);
        this.logger.info("[powerMonitor] message: ", message);
        if (message === "resume") {
          this.logger.info( new Date().toISOString() + " [XmppService][powermonitor] resume - tryToReconnect");
          if (this.electronService.isLinux) {
            this._screenLockStatus.next(false);
          }
          this.tryToReconnect();
          this.broadcaster.broadcast("electronScreenUnlock");
        }
        if (message === "sleep") {
          const wentToSleepAt = Math.floor( (new Date().getTime() / 1000) - 150).toString();
          this.logger.info( new Date().toISOString() + " [XmppService][powermonitor] wentToSleepAt: ", wentToSleepAt);
          this._screenLockStatus.next(true);
          this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][powermonitor] screenLocStatus is true, wentToSleepAt: " + wentToSleepAt);
          localStorage.setItem("wentToSleepAt", wentToSleepAt);
        }
        if (message === "lock") {
          const lockedScreenAt = Math.floor( (new Date().getTime() / 1000) - 150).toString();
          this.logger.info(new Date().toISOString() + " [XmppService][powermonitor] lock-screen: ", lockedScreenAt);
          localStorage.setItem("wentToSleepAt", lockedScreenAt);
          this._screenLockStatus.next(true);
        }
        if (message === "unlock") {
          const unLockedScreenAt = Math.floor( (new Date().getTime() / 1000) - 150).toString();
          this.logger.info(new Date().toISOString() + " [XmppService][powermonitor] unlock: ", unLockedScreenAt);
          this._screenLockStatus.next(false);
          this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][powermonitor] screenLocStatus is false, unLockedScreenAt: " + unLockedScreenAt);
          this.broadcaster.broadcast("electronScreenUnlock");
        }
      });
      this.electronService.ipcRenderer.on("mainMenu", (event, message) => {
        this.logger.info("[mainMenu] message: ", message);
        if (message === "about") {
          this.broadcaster.broadcast("showAboutDialog");
        }
        if (message === "hin-support") {
          this.broadcaster.broadcast("showHinSupport");
        }

      });
    }

    this.broadcaster.on<boolean>("forceInitOmemo").subscribe(() => {
      this.initOmemo();
    });

    this.broadcaster.on<boolean>("checkOmemoQueue").subscribe(() => {
      this._onOmemoTrigger.next(Date.now());
    });

    this.broadcaster.on<any>("UPDATED_LOCAL_OMEMO_DEVICE").subscribe(localDevice => {
      this.omemoService.updateLocalDeviceId(localDevice);
    });


    this._onOmemoTrigger.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(() => {
      // this.logger.warn("[XMPPService][DecryptQueueTriggered]");
      this.databaseService.fetchMessageFromDecryptQueueTop().subscribe(qmessages => {
        // this.logger.warn("[XMPPService][DecryptQueue] processing", qmessages);
        if (!!qmessages[0]) {
          this.decriptOrUseExisting(qmessages[0]).subscribe(decryptedMessage => {
            this.logger.warn("[XMPPService][DecryptQueue] processStep", decryptedMessage);
            if (Object.keys(decryptedMessage).length === 1) {
              this.logger.warn("[XMPPService][DecryptQueue] ignore - already processed", qmessages[0]);
              this.databaseService.removeMessageFromDecryptQueue(decryptedMessage).subscribe(() => {
                this._onOmemoTrigger.next(Date.now());
              });
            } else {
              this.logger.warn("[XMPPService][DecryptQueue] decriptOrUseExisting", qmessages[0].id, qmessages[0].body);
              // this.processOnMessage(decryptedMessage);
              if (!!decryptedMessage.signal) {
                this.logger.warn("[XMPPService][DecryptQueue] SIGNAL", decryptedMessage.signal);
                this.processOnResendSignal(decryptedMessage.signal);
              } else {
                this.processOnMamMessage(decryptedMessage, true);
              }
              this.databaseService.removeMessageFromDecryptQueue(decryptedMessage).subscribe(() => {
                this.logger.warn("[XMPPService][DecryptQueue] triggerNextStep");
                this._onOmemoTrigger.next(Date.now());
              });
            }
          }, err => {
            this.logger.warn("[XMPPService][DecryptQueue] err", qmessages[0].id, err);
            this.databaseService.removeMessageFromDecryptQueue(qmessages[0]).subscribe(() => {
              this._onOmemoTrigger.next(Date.now());
            });
          });
        }
      });
    });
  }

  private initOmemo() {
    this.logger.info("[XmppService][initOmemo]");

    CommonUtil.onOmemoLibsignalLoaded().subscribe((isLoaded) => {
      this.logger.info("[XmppService][initOmemo] libsignal next value", isLoaded);
    }, (error) => {
      // eslint-disable-next-line no-console
      console.warn("[XmppService][initOmemo] libsignal error", error);
    }, async () => {
      this.logger.info("[XmppService][initOmemo] libsignal loaded - ", this.xmpp.jid);
      if (!this.omemoService) {
        this.omemoService = new XmppServiceOmemo(this.xmpp, this.databaseService, this.logger);
      }

      await this.omemoService.init();

      this.store.select(getUnArchivedConversations).pipe(filter(convs => convs.length > 0), take(1)).subscribe(convs => {
        const isAtLeastOneOmemoChat = convs.filter(c => c.encrypted).length > 0;
        this.logger.info("[XmppService][initOmemo] isAtLeastOneOmemoChat", isAtLeastOneOmemoChat, convs.length);

        if (isAtLeastOneOmemoChat) {
          // Check if this is a new OMEMO device
          //
          this.databaseService.getLocalDevice().subscribe(device => {
            this.logger.info("[XmppService][initOmemo] Local Device", device);
            if (!device) {
              // this is new
              // then need to check if we have capacity
              this.getOMEMODevices().then((devices: any[]) => {
                this.logger.info("[XmppService][initOmemo] getOMEMODevices devices", devices);
                const omemoDevicesList = Array.from(devices).filter(d => !!d && !!d.id); // just to remove any trash

                if (omemoDevicesList.length >= CommonUtil.OMEMO_MAX_DEVICES) {
                  this.broadcaster.broadcast("showOMEMOHitMaxLimitDialog");

                  this.store.dispatch(new XmppOMEMOMaxDevicesReached());
                } else {
                  this.startOmemo(true);
                }
              }).catch(err => {
                this.logger.error("[XmppService][initOmemo] getOMEMODevices error", err);
              });
            } else {
              this.startOmemo();
            }
          }, err => {
            this.logger.error("[XmppService][initOmemo] getLocalDevice error", err);
          });
        } else {
          this.startOmemo();
        }
      });
    });
  }

  private startOmemo(forceDeviceUpdateBroadcast = false) {

    this.logger.info("[XmppService][startOmemo]", new Date());
    this.omemoService.start().then(() => {
      this.logger.info("[XmppService][startOmemo] done", new Date());

      // // // FOR TESTING/DEBUG PURPOSE
      // this.omemoService.clearAllAnnouncedDeviceIdsExceptCurrent().then(res => {
      //   this.logger.info("[XmppService][initOmemo] clearAllAnnouncedDeviceIdsExceptCurrent done");
      // });

      this.store.dispatch(new XmppOMEMOInit());
      this._onOmemoTrigger.next(Date.now);

      if (forceDeviceUpdateBroadcast) {
        // update Settings devices list if it's opened;
        this.broadcaster.broadcast("omemo-devices-updated");
      }
    }).catch(err => {
      this.logger.sentryErrorLog("[XmppService][startOmemo] err", err);
      this.logger.error("[XmppService][startOmemo]", err);
    });
  }

  getOMEMODevices() {
    return this.omemoService?.getAnnouncedDevices(this.getUserJid());
  }

  announceDevices(devices: any[]) {
    return this.omemoService.announceDevices(devices);
  }

  private get priorityKey(): string {
    return this.profile.user.id + ":priority";
  }

  getOnMessage(): Observable<Message> {
    return this._onMessage.asObservable();
  }

  getOnMamMessage(): Observable<Message> {
    return this._onMamMessage.asObservable();
  }

  getOnResendSignal(): Observable<any> {
    return this._onProcessOnResendSignal.asObservable();
  }

  getOnDeleteMessage(): Observable<string> {
    return this._onDeleteMessage.asObservable();
  }

  getOnMessageReceipt(): Observable<string> {
    return this._onMessageReceipt.asObservable();
  }

  getOnStampReceived(): Observable<{jid: string, timestamp: number}> {
    return this._onStampReceived.asObservable();
  }

  getOnMucError(): Observable<Message> {
    return this._onMucError.asObservable();
  }

  getOnPresence(): Observable<Presence> {
    return this._onPresence.asObservable();
  }

  // getOnMucAvailable(): Observable<MucAvailable> {
  //   return this._onMucAvailable.asObservable();
  // }

  getOnMucInvite(): Observable<any> {
    return this._onMucInvite.asObservable();
  }

  getPriority(): Observable<number> {
    return this._priority.asObservable().pipe(distinctUntilChanged());
  }

  setPriority(priority: number) {
    localStorage.setItem(this.priorityKey, priority.toString());
    this._priority.next(priority);
  }

  /**
   * Operations on room/Conversation
   */
  block(conversationTarget: string): Observable<boolean> {
    const response = new Subject<boolean>();
    this.xmpp.block(conversationTarget, (err) => {
      this.zone.run(() => {
        if (err) {
          response.next(false);
        } else {
          response.next(true);
        }
      });
    });

    return response.asObservable();
  }

  unblock(conversationTarget: string): Observable<boolean> {
    const response = new Subject<boolean>();
    this.xmpp.unblock(conversationTarget, (err) => {

      this.zone.run(() => {
        if (err) {
          response.next(false);
        } else {
          response.next(true);
        }

        // PRASHANT_COMMENT need of this?
        this.sendPresence();
        this.xmpp.sendPresence({
          to: conversationTarget,
          type: "probe"
        });

        this.updateContactFromVCard(conversationTarget);

      });
    });

    return response.asObservable();
  }

  joinRoom(roomId: string, maxstanzas = 0) {
    this.logger.info("[XmppService][joinRoom]", roomId, maxstanzas);

    if (this.xmpp) {
      // turn this on when we need to check if joinRoom is called w/o valid xmpp connection
      // this.logger.info("[XmppService][joinRoomdebugx]", roomId, this.xmpp.jid.bare);
      if (maxstanzas === 0) {
        this.xmpp.joinRoom(roomId, this.xmpp.jid.bare, {
          joinMuc: {history: {
            maxstanzas: "0"
          }}
        });
      } else {
        this.xmpp.joinRoom(roomId, this.xmpp.jid.bare, {
          joinMuc: {history: {
            maxstanzas: maxstanzas
          }}
        });
      }

    //   this.roomsJoinedTime[roomId] = Math.round(Date.now() / 1000);
    }
  }

  updateNick(bare: string, newNick: string) {
    // this.logger.info("[XmppService][updateNick]", {bare});

    if (bare === this.xmpp.jid.bare) {
      // console.warn("[XmppService][updateNick]", "ignore own presence");
      return;
    }

    this.store.select(state => getContactById(state , bare)).pipe(take(1)).subscribe(c => {
      if (c) {
        const oldNick = c.name;
        if (newNick !== oldNick) {
          this.logger.info("[XmppService][updateNick]", {bare, newNick, oldNick, c});

          // update redux
          this.store.dispatch(new ContactBulkNicknameUpdate([{jid: c, nickname: newNick}]));

          // update in DB
          c.name = newNick;
          this.databaseService.createOrUpdateContacts([c]).subscribe();
        }
      }
    });
  }

  updateContactFromVCard(bare: string): Observable<VCard> {
    this.logger.info("[XmppService][updateContactFromVCard] bare", bare);
    const response = new Subject<VCard>();

    if (this.isXmppConnected && this.networkOnline) {
      this.xmpp.getVCard(bare, (err, res) => {
        if (res && !err) {
          this.logger.info("[XmppService][updateContactFromVCard] res for bare: ", bare, res);

          if (environment.isCordova && bare === this.xmpp.jid.bare && res.vCardTemp.vncAppUser !== "yes") {
            res.vCardTemp.vncAppUser = "yes";
            this.publishVCards(res.vCardTemp).pipe(take(1)).subscribe();
          }
          if (res.vCardTemp.vncAppUser) {
            this.store.select(state => getContactById(state , bare)).pipe(take(1)).subscribe(c => {
              if (c && c.vncAppUser !== res.vCardTemp.vncAppUser) {
                this.store.dispatch(new ContactBulkAppStatusUpdate([{jid: c, vncAppUser: res.vCardTemp.vncAppUser}]));
              }
            });
          }
          let vCard: VCard = {
            jid: res.from.bare ? res.from : this.xmpp.jid,
            vCard: res.vCardTemp as ContactInformation
          };
          if (bare === this.xmpp.jid.bare) {
            const fullName = this.profile.user.firstName + " " + this.profile.user.lastName;
            if (!!vCard.vCard.fullName) {
              vCard.vCard.fullName = fullName;
            }
            this.logger.info("[XmppService][updateContactFromVCard] check with this.profile: ", this.profile, vCard);
          }
          if (res.vCardTemp.nicknames) {
            if (bare === this.xmpp.jid.bare && !this.sentNickname) {
              this.sendPresence(res.vCardTemp.nicknames[0]);
              this.sentNickname = true;
            } else if (bare !== this.xmpp.jid.bare) {

              this.store.select(state => getContactById(state , bare)).pipe(take(1)).subscribe(c => {
                if (c) {
                  this.store.dispatch(new ContactBulkNicknameUpdate([{jid: c, nickname: res.vCardTemp.nicknames[0]}]));
                }
              });
            }
          }

          this.store.dispatch(new ContactAddVCard(vCard));
          response.next(vCard);
        } else {
          this.logger.info("[XmppService][updateContactFromVCard] not found avatar for: ", bare, err);
          response.error(err);
        }
      });
    } else if (!this.isXmppConnected) {
      response.error(ConstantsUtil.ERROR_XMPP_NOT_CONNECTED);
    } else if (!this.networkOnline) {
      response.error(ConstantsUtil.ERROR_APP_OFFLINE);
    }

    return response.asObservable();
  }

  sendPresence(nickname?: string) {
    this.logger.info("[XmppService][sendPresence]", nickname);
    let status;

    this.store.select(getUserStatus).pipe(take(1)).subscribe(res => status = res);
    status = getUserStatusType(status);
    let options: any = {
        show: status.slug,
        priority: this._priority.getValue(),
        caps: this.xmpp.disco.caps
      };
    if (status.slug === "available") {
      options = {
        priority: this._priority.getValue(),
        caps: this.xmpp.disco.caps
      };
    }
    if (nickname) {
      this.nickname = nickname;
    }
    if (this.nickname) { // use old nickname
      options.nick = this.nickname;
    }
    this.logger.info("[XmppService][sendPresence] options:", options);
    this.xmpp.sendPresence(options);
  }

  sendAvatarPresence(avatarId?: string) {
    this.logger.info("[XmppService][sendPresence]", avatarId);
    let status;

    this.store.select(getUserStatus).pipe(take(1)).subscribe(res => status = res);
    status = getUserStatusType(status);
    let options: any = {
        show: status.slug,
        priority: this._priority.getValue(),
        caps: this.xmpp.disco.caps
      };
    if (status.slug === "available") {
      options = {
        priority: this._priority.getValue(),
        caps: this.xmpp.disco.caps
      };
    }
    if (this.nickname) { // use old nickname if exists
      options.nick = this.nickname;
    }
    options.avatarId = avatarId;
    this.logger.info("[XmppService][sendPresence] options:", options);
    this.xmpp.sendPresence(options);
  }

  sendVcards(vCards) {
    this.xmpp.sendPresence({vCards});
  }

  private setupXmppConnectionStatusUpdate() {
    // here we track changes Disconnected -> SessionStarted and SessionStarted -> Disconnected
    this._connectionStatus.pipe(filter(status => status === XmppConnectionStatus.Disconnected
        || status === XmppConnectionStatus.SessionStarted)
      , map(status => status === XmppConnectionStatus.SessionStarted))
      .subscribe(status => {
        this.logger.info("[XmppService][SetXmppConnection]", status);
        this.store.dispatch(new SetXmppConnection(status));

        // if we are in a 'Disconnected' state, then tried to connect again and
        // failed by timeout (e.g we have a wifi, but no Internet connection) then a 'Disconnected' state will be called again
        // BUT, the below 'this.store.select(getIsConnectedXMPP)' will not be called again because a select is firing only on changes
        // so we need to fire a reconnect manually if we faced a onnect timeout issue
        //
        // See below what we do in a "stream:error" xmpp callback when "connection-timeout" happened.
      });
  }

  private setupXmppIsConnectedStateUpdate() {
    // this.appStore.map(state => state.isXmppConnected).subscribe(chatConnected => { });

    this.store.select(getIsConnectedXMPP).subscribe(chatConnected => {
      this.logger.info("[XmppService][getIsConnectedXMPP]", chatConnected);
      this.isXmppConnected = chatConnected;

      if (!!localStorage.getItem("pendingFCMToken")) {
        let token = localStorage.getItem("pendingFCMToken");
        const osSlug = CommonUtil.isOnIOS() ? "ios" : "android";

        this.notificationService.storeFCMToken(token, device.uuid, osSlug).pipe(tap(() => {
          this.logger.info("[XmppService] Firebase token set", osSlug, token);
          this.store.dispatch(new SetFirebaseToken(token));
          localStorage.setItem(ConstantsUtil.KEY_FIREBASE_TOKEN , token);
          localStorage.removeItem("pendingFCMToken");
        }, err => {
          this.logger.error("[XmppService] Firebase token not set", err);
          CommonUtil.sentryLog("[XmppService] Setting Firebase token error: " + err);
        }));
      }

      if (this.isXmppConnected) {
        this.invalidateReconnectionTimer();
        this.enableKeepAlive();

        if (environment.isCordova) {
          this.markActive();
        }

        // a 'disableKeepAlive' is automatically will happpen on 'disconnected' event
        const electronIsSnap = localStorage.getItem("electronIsSnap");
        const electronSnapAudioConnected = localStorage.getItem("electronSnapAudioConnected");
        const electronSnapCamConnected = localStorage.getItem("electronSnapCamConnected");

        try {
          if (!!electronIsSnap && (electronIsSnap === "true") && ((electronSnapAudioConnected === "false") || (electronSnapCamConnected === "false"))) {
            this.broadcaster.broadcast("showWelcomeDialog", true);
          }
        } catch (error) {
          this.logger.info("error in snap check: ", error);
        }


        if (localStorage.getItem("oauthFirstLogin") === "loggedIn") {
          this.zone.run(() => {
            localStorage.setItem("oauthFirstLogin", "welcomeShown");
            this.broadcaster.broadcast("showWelcomeDialog");
          });
        }
        let remainOauth = parseInt(localStorage.getItem("HinTokenValidUntil")) - new Date().getTime();
        // limit: 7 days
        if (remainOauth < 604800000) {
          this.zone.run(() => {
            this.broadcaster.broadcast("showRemainDialog");
          });
        }
        if (environment.theme === "hin") {
          setTimeout(() => {
            this.broadcaster.broadcast("hinXmppConn");
          }, 3000);
        } else {
          setTimeout(() => {
            this.xmpp.getRoster((err, data) => {
              this.logger.info("[sensor] getRoster data, err: ", data, err);
              if (!!data && !!data.roster && !!data.roster.items && (data.roster.items.length > 0)) {
                data.roster.items.forEach(item => {
                  // this.logger.info("[sensor] rosterItem: ", item);
                  let conversationTarget: string = item.jid.bare;
                  if (conversationTarget.indexOf("@conference") > -1) {
                    // this.logger.info("[sensor] need to unsubscribe from: ", conversationTarget);
                    this.xmpp.removeRosterItem(conversationTarget, (res, error) => {
                      this.logger.info("[sensor] unsubscribe res: ", res, error);
                    });
                    // this.xmpp.unsubscribe(conversationTarget);
                  } else {
                    if (!this.rosterItemJids.includes(conversationTarget)) {
                      if (!conversationTarget.startsWith("broadcast")) {
                        this.rosterItemJids.push(conversationTarget);
                      }
                    }
                    if ((item.subscription !== "both")) {
                      this.xmpp.acceptSubscription(conversationTarget);
                      this.xmpp.subscribe(conversationTarget);
                    }
                  }
                });
              }
            });
          }, 3000);
        }
        if (this.electronService.isElectron) {
          try {
            let pendingCmdUrlOpen = this.electronService.getFromStorage("openUri");
            if (pendingCmdUrlOpen && (pendingCmdUrlOpen !== "")) {
              this.electronService.deleteFromStorage("openUri");
              this.broadcaster.broadcast("electronOpenUri", pendingCmdUrlOpen);
            }
          } catch (error) {
            this.logger.error("electron process pending openUri: ", error);
          }
        }
        const lastConnectedAt = Math.floor(( new Date().getTime() ) / 1000).toString();
        // this.logger.error("[XmppService][sensor][lastConnectedAt]", lastConnectedAt);
        localStorage.setItem("lastConnectedAt", lastConnectedAt);
        setTimeout(() => {
          this.broadcaster.broadcast("maybeRenewJitsiAuth");
        }, 5000);
      } else {
        this.store.dispatch(new ConversationResetAllActivated());
        this.runReconnectionTimer();
      }
    });
  }

  private runReconnectionTimer() {
    if (this.reconnectionTimer$) {
      this.logger.info("[XmppService][runReconnectionTimer] return, timer already defined");
      return;
    }

    this.logger.info("[XmppService][runReconnectionTimer]");
    this.reconnectionTimer$ = timer(0, XmppService.XMPP_RECONNECTION_INTERVAL).subscribe(() => {
      this.tryToReconnect();
    });
  }

  private invalidateReconnectionTimer() {
    this.logger.info("[XmppService][invalidateReconnectionTimer]");
    if (this.reconnectionTimer$) {
      this.reconnectionTimer$.unsubscribe();
      this.reconnectionTimer$ = null;
    }
  }

  private setupNetworkChangesListener() {
    if (this.networkSubscription$) {
      return;
    }
    this.networkSubscription$ = this.store.select(getNetworkInformation).pipe(distinctUntilChanged()).subscribe(information => {
      this.logger.info("[XmppService][getNetworkInformation]", information.onlineState, information, typeof information.inBackground);
      this.networkOnline = information.onlineState;
      this.inBackground = information.inBackground; // 'inBackground' only for mobile (based on pause/resume events)

      if (!this.networkOnline) {
        this.store.dispatch(new ConversationResetAllActivated());
      }

      if ((typeof this.inBackground !== "undefined") && this.isXmppConnected) {
        if (this.inBackground) {
          this.logger.info("[XmppService] going to mark inactive and disable keepalive");
          this.markInactive();
          this.disableKeepAlive();
        } else {
          this.markActive();
          this.enableKeepAlive();
        }
      }

      if (environment.isCordova && CommonUtil.isOnAndroid() && window.appInBackground) {
        return;
      }
      this.runReconnectionTimer();
    });
  }

  private tryToReconnect() {
    this.logger.info(new Date().toISOString() + " [XmppService][tryToReconnect]", this.isXmppConnected, this.networkOnline, this.isLoggedIn, this.xmppLoggedOut, this.inBackground, this._connectionStatus.getValue());
    this.logger.info(new Date().toISOString() + " [XmppService][tryToReconnect] screenLockState", this._screenLockStatus.value);
    const ualower = navigator.userAgent.toLowerCase();
    const isLinuxElectron = ((ualower.indexOf("electron") > -1) && (ualower.indexOf("linux") > -1));
    if (environment.isElectron) {
      this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][tryToReconnect] screenLockState: " + this._screenLockStatus.value + " on Linux " + isLinuxElectron);
    }
    if (this._screenLockStatus.value) {
      if (!isLinuxElectron) {
        this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][tryToReconnect] screenLockState bailout: " + this._screenLockStatus.value + " " + window.process.platform);
        this.logger.info(new Date().toISOString() + " [XmppService][tryToReconnect] screenLockState - skip connect");
        return;
      }
    }
    if (!this.isXmppConnected && this.networkOnline
      && this.isLoggedIn && !this.xmppLoggedOut && !this.inBackground) {

      if (this._connectionStatus.getValue() === XmppConnectionStatus.Connecting){
        this.logger.info("[XmppService][tryToReconnect]: skip, in Connecting state");
        if (environment.isElectron) {
          this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][tryToReconnect]: skip, in Connecting state: " + this._screenLockStatus.value);
        }

      } else {
        this.logger.info("[XmppService][tryToReconnect]: Trying to restore XMPP connection");
        if (environment.isElectron) {
          this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][tryToReconnect]: skip, in Connecting state: " + this._screenLockStatus.value);
        }
        this.xmppLoaded$.pipe(filter(v => !!v), take(1)).subscribe(() => {
          this.login();
        });
      }
    } else {
      this.invalidateReconnectionTimer();
    }
  }

  private async setupXMPPConnection() {
    this.logger.info("[XmppService][setupXMPPConnection]");

    this.xmppLoggedOut = false;

    const basicConfig = {
      sendReceipts: true,
      lang: "en",
      timeout: environment.xmppTimeout
    };

    if (this.profile.useBOSHforWebclient) {
      basicConfig["transport"] = "bosh";
      basicConfig["boshURL"] = this.profile.boshURL;
      basicConfig["useStreamManagement"] = false;
    } else {
      basicConfig["transport"] = "websocket";
      basicConfig["wsURL"] = this.profile.xmppWsUrl;
      basicConfig["useStreamManagement"] = true;
    }

    this.logger.info("[XmppService][setupXMPPConnection] basicConfig:", basicConfig);
    normalizeCommonJSImport(import("stanza.io")).then(XMPP => {
      this.xmpp = XMPP.createClient(basicConfig);
      this.configureXMPPClient();
      this.registerForEvents();
      this.xmppLoaded$.next(true);
    });

  }

  private login() {
    const moreThan5MinsInBackground = (Date.now() / 1000 - this.LastInactiveTime) > 300;
    this.logger.info("[XmppService][login], moreThan5MinsInBackground: ", moreThan5MinsInBackground);

    if (this._connectionStatus.getValue() === XmppConnectionStatus.Connecting){
        this.logger.info("[XMPPService][login], state is CONNECTING so skip a login.");
        return;
    }
    if (this._connectionStatus.getValue() === XmppConnectionStatus.Connected){
        this.logger.info("[XMPPService][login], state is CONNECTED so skip a login.");
        return;
    }
    if (this._connectionStatus.getValue() === XmppConnectionStatus.SessionStarted){
        this.logger.info("[XMPPService][login], state is SESSION-STARTED so skip a login.");
        return;
    }

    if (!this.profile.useBOSHforWebclient &&
      (!environment.isCordova || (environment.isCordova && moreThan5MinsInBackground))) {
      // manually prevent a session resumption
      this.logger.info("[XMPPService][login] reset SM");
      this.xmpp.sm.failed();
    }

    const jid = this.getUserJid();
    this.store.dispatch(new SetBare(jid));

    const connectUserConfig = {
      jid: jid,
      password: this.profile.secret
    };

    // set xmpp resource
    let xmppResourceName: string;
    // Web -> always generate new resource to be able to use multiple tabs
    if (!environment.isCordova && !environment.isElectron) {
      xmppResourceName = `vnctalk_${CommonUtil.getDeviceId()}_${CommonUtil.randomId()}`;
    } else {
      xmppResourceName = localStorage.getItem("xmppResourceName");
      if (!xmppResourceName || xmppResourceName.indexOf("vnctalk") === -1) {
        xmppResourceName = `vnctalk_${CommonUtil.getDeviceId()}`;
        localStorage.setItem("xmppResourceName", xmppResourceName);
      }
    }
    this.xmpp.config["resource"] = xmppResourceName;

    this.logger.info("[XmppService][login] connectUserConfig:", connectUserConfig, xmppResourceName);

    this._connectionStatus.next(XmppConnectionStatus.Connecting);
    this.xmpp.connect(connectUserConfig);
  }

  private getUserJid() {
    return Array.isArray(this.profile.user.email) ? this.profile.user.email[0] : this.profile.user.email;
  }

  private configureXMPPClient() {
    this.logger.info("[XMPPService][configureXMPPClient]");

    this._setUpHTTPUploadModule();
    this._setUpMUCRegisterModule();
    this._setupLastActivityModule();
    this._setupLastActivityBatchModule();

    this._setUpCustomStanzaHadler();

    this.xmpp.uploadGroupAvatar = (conversationTarget, photo, cb) => {
      return this.xmpp.sendIq({
        to: conversationTarget,
        type: "set",
        vCardTemp: {photo: photo}
      }, cb);
    };

    this.xmpp.disco.addFeature("urn:xmpp:message-correct:0");

    let NS = "xmpp:vnctalk";
    let utils = this.xmpp.stanzas.utils;
    let messageAttribute = this.xmpp.stanzas.define({
      name: "attachment",
      element: "attachment",
      namespace: NS,
      fields: {
        url: utils.textSub(NS, "url"),
        fileSize: utils.textSub(NS, "fileSize"),
        fileName: utils.textSub(NS, "fileName"),
        fileType: utils.textSub(NS, "fileType"),
        isCopy: utils.textSub(NS, "isCopy"),
        owncloud: utils.text()
      }
    });

    let owncloudAttribute = this.xmpp.stanzas.define({
      name: "owncloud",
      element: "owncloud",
      namespace: NS,
      fields: {
        url: utils.textSub(NS, "url"),
        fileSize: utils.textSub(NS, "fileSize"),
        fileName: utils.textSub(NS, "fileName"),
        fileType: utils.textSub(NS, "fileType"),
        data: utils.text()
      }
    });

    let JsonDocument = this.xmpp.stanzas.define({
      name: "_document",
      element: "document",
      namespace: "stanza:io:json",
      fields: {
        key: utils.attribute("key"),
        value: utils.text()
      }
    });
    let JsonDocuments = this.xmpp.stanzas.define({
      name: "documents",
      namespace: "stanza:io:json",
      element: "documents"
    });

    this.xmpp.stanzas.extend(JsonDocuments, JsonDocument, "list");
    this.xmpp.stanzas.withDefinition("query", "jabber:iq:private", (PrivateStorage) => {
      this.xmpp.stanzas.extend(PrivateStorage, JsonDocuments);
    });



    /**
     * Docs is a Map of key: value.
     * This function will do all the necessary XML/JSON encoding.
     */
    this.xmpp.setPrivateDocuments = (docs, cb) => {
      const encodedDocs = [];
      for (const [key, value] of Object.entries(docs)) {
        encodedDocs.push({
          key: key,
          value: JSON.stringify(value)
        });
      }

      this.xmpp.setPrivateData({documents: {list: encodedDocs}}, cb);
    };

    let locationAttribute = this.xmpp.stanzas.define({
      name: "location",
      element: "location",
      namespace: NS,
      fields: {
        lat: utils.textSub(NS, "lat"),
        lng: utils.textSub(NS, "lng"),
        address: utils.textSub(NS, "address"),
        label: utils.textSub(NS, "label")
      }
    });

    let notificationAttribute = this.xmpp.stanzas.define({
      name: "notification",
      element: "notification",
      namespace: NS,
      fields: {
        type: utils.textSub(NS, "type"),
        action: utils.textSub(NS, "action"),
        target: utils.textSub(NS, "target"),
        content: utils.textSub(NS, "content")
      }
    });

    let recordingNotify = this.xmpp.stanzas.define({
      name: "recording_notify",
      element: "recording_notify",
      namespace: NS,
      fields: {
        file: utils.textSub(NS, "file"),
        conv: utils.textSub(NS, "conv"),
        callId: utils.textSub(NS, "callId")
      }
    });

    let vncTalkConference = this.xmpp.stanzas.define({
      name: "vncTalkConference",
      element: "vncTalkConference",
      namespace: NS,
      fields: {
        from: utils.textSub(NS, "from"),
        to: utils.textSub(NS, "to"),
        conferenceId: utils.textSub(NS, "conferenceId"),
        oldConferenceId: utils.textSub(NS, "oldConferenceId"),
        jitsiRoom: utils.textSub(NS, "jitsiRoom"),
        jitsiURL: utils.textSub(NS, "jitsiURL"),
        jitsiXmppUrl: utils.textSub(NS, "jitsiXmppUrl"),
        jitsiXmppPort: utils.textSub(NS, "jitsiXmppPort"),
        reason: utils.textSub(NS, "reason"),
        conferenceType: utils.textSub(NS, "conferenceType"),
        duration: utils.textSub(NS, "duration"),
        eventType: utils.textSub(NS, "eventType"),
        timestamp: utils.textSub(NS, "timestamp"),
        skipAddUserToChatWhenAddToCall: utils.textSub(NS, "skipAddUserToChatWhenAddToCall")
      }
    });

    let vncTalkConferenceScheduler = this.xmpp.stanzas.define({
      name: "vncTalkConferenceScheduler",
      element: "vncTalkConferenceScheduler",
      namespace: NS,
      fields: {
        subject: utils.textSub(NS, "subject"),
        description: utils.textSub(NS, "description"),
        owner: utils.textSub(NS, "owner"),
        ownerName: utils.textSub(NS, "owner"),
        invitees: utils.textSub(NS, "invitees"),
        startTime: utils.textSub(NS, "startTime"),
        endTime: utils.textSub(NS, "endTime"),
        password: utils.textSub(NS, "password"),
        serverURL: utils.textSub(NS, "serverURL"),
        conferenceType: utils.textSub(NS, "conferenceType"),
        timestamp: utils.textSub(NS, "timestamp")
      }
    });

    let conferenceResponse = this.xmpp.stanzas.define({
      name: "conferenceResponse",
      element: "conferenceResponse",
      namespace: NS,
      fields: {
        receiver: utils.textSub(NS, "receiver"),
        reason: utils.textSub(NS, "reason")
      }
    });

    let meetingMessage = this.xmpp.stanzas.define({
      name: "meetingMessage",
      element: "meetingMessage",
      namespace: NS,
      fields: {
        to: utils.textSub(NS, "to")
      }
    });

    const toList = {
      set: function set(lists) {
        const self = this;
        lists.forEach(function(bare) {
          const to = utils.createElement(NS, "to", NS);
          utils.setText(to, bare ? bare.toString() : bare);
          self.xml.appendChild(to);
        });
      }
    };

    const rosterList = {
      set: function set(lists) {
        const self = this;
        lists.forEach(function(name) {
          const roster = utils.createElement(NS, "roster", NS);
          utils.setText(roster, name.toString());
          self.xml.appendChild(roster);
        });
      }
    };
    const tagList = {
      set: function set(lists) {
        const self = this;
        lists.forEach(function (tags) {
          const tag = utils.createElement(NS, "tag", NS);
          utils.setText(tag, tags.toString());
          self.xml.appendChild(tag);
        });
      }
    };

    let vncTalkBroadcast = this.xmpp.stanzas.define({
      name: "vncTalkBroadcast",
      element: "vncTalkBroadcast",
      namespace: NS,
      fields: {
        title: utils.attribute("title"),
	description: utils.attribute("description"),
        origtarget: utils.attribute("origtarget"),
        to: toList,
        roster: rosterList,
	tags: tagList,
        avatarup: utils.attribute("avatarup")
      }
    });


    let vncTalkMuc = this.xmpp.stanzas.define({
      name: "vncTalkMuc",
      element: "vncTalkMuc",
      namespace: NS,
      fields: {
        from: utils.textSub(NS, "from"),
        to: utils.textSub(NS, "to"),
        conferenceId: utils.textSub(NS, "conferenceId"),
        eventType: utils.textSub(NS, "eventType")
      }
    });

    let groupActionMessage = this.xmpp.stanzas.define({
      name: "group_action",
      element: "group_action",
      namespace: NS,
      fields: {
        type: utils.textSub(NS, "type"),
        data: utils.textSub(NS, "data")
      }
    });

    let replaceMessage = this.xmpp.stanzas.define({
      name: "replace",
      element: "replace",
      namespace: "urn:xmpp:message-correct:0",
      fields: {
        id: utils.attribute("id"),
        value: utils.text()
      }
    });

    let prompt = this.xmpp.stanzas.define({
      name: "prompt",
      element: "prompt",
      namespace: NS,
      fields: {
        value: utils.text(),
        id: utils.attribute("id")
      }
    });

    let originalMessage = this.xmpp.stanzas.define({
      name: "originalMessage",
      element: "originalMessage",
      namespace: NS,
      fields: {
        id: utils.textSub(NS, "id"),
        from: utils.textSub(NS, "from"),
        body: utils.textSub(NS, "body"),
        timestamp: utils.textSub(NS, "timestamp"),
        htmlBody: utils.textSub(NS, "htmlBody"),
        attachment: utils.textSub(NS, "attachment"),
        location: utils.textSub(NS, "location"),
        replyMessage: utils.textSub(NS, "replyMessage"),
        broadcast_id: utils.textSub(NS, "broadcast_id"),
        broadcast_owner: utils.textSub(NS, "broadcast_owner"),
        broadcast_title: utils.textSub(NS, "broadcast_title")
      }
    });

    let urlPreviewDataMessage = this.xmpp.stanzas.define({
      name: "urlPreviewData",
      element: "urlPreviewData",
      namespace: NS,
      fields: {
        title: utils.textSub(NS, "title"),
        background: utils.textSub(NS, "background"),
        description: utils.textSub(NS, "description"),
      }
    });

    let redminePreviewMessage = this.xmpp.stanzas.define({
      name: "redminePreview",
      element: "redminePreview",
      namespace: NS,
      fields: {
        data: utils.textSub(NS, "data"),
      }
    });

    const omemoHistoryExchangeSignalMessage = this.xmpp.stanzas.define({
      name: "omemoHistoryExchangeSignal",
      element: "omemoHistoryExchangeSignal",
      namespace: NS,
      fields: {
        data: utils.textSub(NS, "data"),
      }
    });

    let forwardMessage = this.xmpp.stanzas.define({
      name: "forwardMessage",
      element: "forwardMessage",
      namespace: NS,
      fields: {
        id: utils.textSub(NS, "id"),
        from: utils.textSub(NS, "from"),
        timestamp: utils.textSub(NS, "timestamp")
      }
    });

    let htmlMessage = this.xmpp.stanzas.define({
      name: "html",
      element: "html",
      namespace: "http://jabber.org/protocol/xhtml-im",
      fields: {
        body: utils.textSub("http://www.w3.org/1999/xhtml", "body")
      }
    });

    let startFile = this.xmpp.stanzas.define({
      name: "startFile",
      element: "startFile",
      namespace: NS,
      fields: {
        type: utils.textSub(NS, "type")
      }
    });

    let stamp = this.xmpp.stanzas.define({
      name: "stamp",
      element: "stamp",
      namespace: "xmpp:vnctalk:stamp",
      fields: {
        stamp: utils.attribute("stamp"),
        from: utils.attribute("from")
      }
    });

    let signal = this.xmpp.stanzas.define({
      name: "signal",
      element: "signal",
      namespace: "xmpp:vnctalk:signal",
      fields: {
        type: utils.textSub(NS, "type"),
        target: utils.textSub(NS, "target"),
        data: utils.textSub(NS, "data")
      }
    });

    let notification = this.xmpp.stanzas.define({
      name: "notification",
      element: "notification",
      namespace: "http://vnc.biz/xmpp/muc#hook",
      fields: {
        jid: utils.attribute("jid")
      }
    });

    let groupInfo = this.xmpp.stanzas.define({
      name: "x",
      element: "x",
      namespace: "xmpp:vnctalk:update",
      fields: {
        affiliations: utils.textSub("xmpp:vnctalk:update", "affiliations"),
        data: utils.textSub("xmpp:vnctalk:update", "data")
      }
    });
    this.xmpp.disco.addFeature("jabber:x:expire");
    let messageExpire = this.xmpp.stanzas.define({
      name: "expire",
      element: "x",
      namespace: "jabber:x:expire",
      fields: {
        seconds: utils.attribute("seconds")
      }
    });

    let rfcAttribute = this.xmpp.stanzas.define({
      name: "rfc",
      element: "rfc",
      namespace: NS,
      fields: {
        message: utils.textSub(NS, "message"),
        token: utils.textSub(NS, "token")
      }
    });

    this.xmpp.stanzas.withMessage((Message) => {
      if (!this.setupExtend) {
        this.logger.info("call extend");
        this.setupExtend = true;
        this.xmpp.stanzas.extend(Message, notificationAttribute);
        this.xmpp.stanzas.extend(Message, messageAttribute);
        this.xmpp.stanzas.extend(Message, messageExpire);
        this.xmpp.stanzas.extend(Message, owncloudAttribute);
        this.xmpp.stanzas.extend(Message, locationAttribute);
        this.xmpp.stanzas.extend(Message, replaceMessage);
        this.xmpp.stanzas.extend(Message, groupActionMessage);
        this.xmpp.stanzas.extend(Message, vncTalkConference);
        this.xmpp.stanzas.extend(Message, vncTalkConferenceScheduler);
        this.xmpp.stanzas.extend(Message, conferenceResponse);
        this.xmpp.stanzas.extend(Message, vncTalkBroadcast);
        this.xmpp.stanzas.extend(Message, vncTalkMuc);
        this.xmpp.stanzas.extend(Message, originalMessage);
        this.xmpp.stanzas.extend(Message, urlPreviewDataMessage);
        this.xmpp.stanzas.extend(Message, redminePreviewMessage);
        this.xmpp.stanzas.extend(Message, omemoHistoryExchangeSignalMessage);
        this.xmpp.stanzas.extend(Message, forwardMessage);
        this.xmpp.stanzas.extend(Message, startFile);
        this.xmpp.stanzas.extend(Message, stamp);
        this.xmpp.stanzas.extend(Message, prompt);
        this.xmpp.stanzas.extend(Message, signal);
        this.xmpp.stanzas.extend(Message, notification);
        this.xmpp.stanzas.extend(Message, htmlMessage);
        this.xmpp.stanzas.extend(Message, meetingMessage);
        this.xmpp.stanzas.extend(Message, recordingNotify);
        this.xmpp.stanzas.extend(Message, rfcAttribute);
        this.xmpp.stanzas.extend(Message, groupInfo);
      }
    });


    // message server delivery response
    // https://github.com/otalk/jxt-xmpp/blob/master/src/iq.js
    //
    // <iq type='result' to='george@dev2.zimbra-vnc.de/vnctalk_52zxpjnrk3' from='dev2.zimbra-vnc.de' id='lx133' t='1578339265' xmlns='jabber:client' f='9ynx0bwlvs'/>
    this.xmpp.stanzas.define({
        name: "iq",
        namespace: "jabber:client",
        element: "iq",
        topLevel: true,
        fields: {
            t: utils.numberAttribute("t"), // message timestamp
            f: utils.attribute("f"), // message id
            id: utils.attribute("id"),
            to: utils.jidAttribute("to", true),
            from: utils.jidAttribute("from", true),
            type: utils.attribute("type")
        }
    });


    this.extendVCardTemp();
  }

  private extendVCardTemp(): void {
      let JXT = this.xmpp.stanzas;
      let Utils = JXT.utils;
      let NS = "vcard-temp";
      let VCardTemp = JXT.define({
          name: "vCardTemp",
          namespace: NS,
          element: "vCard",
          fields: {
              role: Utils.textSub(NS, "ROLE"),
              website: Utils.textSub(NS, "URL"),
              vncAppUser: Utils.textSub(NS, "X-VNC-APPUSER"),
              title: Utils.textSub(NS, "TITLE"),
              description: Utils.textSub(NS, "DESC"),
              fullName: Utils.textSub(NS, "FN"),
              birthday: Utils.dateSub(NS, "BDAY"),
              nicknames: Utils.multiTextSub(NS, "NICKNAME"),
              jids: Utils.multiTextSub(NS, "JABBERID")
          }
      });

      let Email = JXT.define({
          name: "_email",
          namespace: NS,
          element: "EMAIL",
          fields: {
              email: Utils.textSub(NS, "USERID"),
              home: Utils.boolSub(NS, "HOME"),
              work: Utils.boolSub(NS, "WORK"),
              preferred: Utils.boolSub(NS, "PREF")
          }
      });

      let PhoneNumber = JXT.define({
          name: "_tel",
          namespace: NS,
          element: "TEL",
          fields: {
              number: Utils.textSub(NS, "NUMBER"),
              home: Utils.boolSub(NS, "HOME"),
              work: Utils.boolSub(NS, "WORK"),
              mobile: Utils.boolSub(NS, "CELL"),
              preferred: Utils.boolSub(NS, "PREF")
          }
      });

      let Address = JXT.define({
          name: "_address",
          namespace: NS,
          element: "ADR",
          fields: {
              street: Utils.textSub(NS, "STREET"),
              street2: Utils.textSub(NS, "EXTADD"),
              country: Utils.textSub(NS, "CTRY"),
              city: Utils.textSub(NS, "LOCALITY"),
              region: Utils.textSub(NS, "REGION"),
              postalCode: Utils.textSub(NS, "PCODE"),
              pobox: Utils.textSub(NS, "POBOX"),
              home: Utils.boolSub(NS, "HOME"),
              work: Utils.boolSub(NS, "WORK"),
              preferred: Utils.boolSub(NS, "PREF")
          }
      });

      let Organization = JXT.define({
          name: "organization",
          namespace: NS,
          element: "ORG",
          fields: {
              name: Utils.textSub(NS, "ORGNAME"),
              unit: Utils.textSub(NS, "ORGUNIT")
          }
      });

      let Name = JXT.define({
          name: "name",
          namespace: NS,
          element: "N",
          fields: {
              family: Utils.textSub(NS, "FAMILY"),
              given: Utils.textSub(NS, "GIVEN"),
              middle: Utils.textSub(NS, "MIDDLE"),
              prefix: Utils.textSub(NS, "PREFIX"),
              suffix: Utils.textSub(NS, "SUFFIX")
          }
      });

      let Photo = JXT.define({
          name: "photo",
          namespace: NS,
          element: "PHOTO",
          fields: {
              type: Utils.textSub(NS, "TYPE"),
              data: Utils.textSub(NS, "BINVAL"),
              url: Utils.textSub(NS, "EXTVAL")
          }
      });

      JXT.extend(VCardTemp, Email, "emails");
      JXT.extend(VCardTemp, Address, "addresses");
      JXT.extend(VCardTemp, PhoneNumber, "phoneNumbers");
      JXT.extend(VCardTemp, Organization);
      JXT.extend(VCardTemp, Name);
      JXT.extend(VCardTemp, Photo);
      JXT.extendIQ(VCardTemp);
  }

  private registerForEvents() {
    this.logger.info("[XmppService.registerForEvents]");

    this.xmpp.on("session:started", this.onSessionStarted.bind(this));

    this.xmpp.on("stream:management:resumed", this.onSessionResumed.bind(this));

    this.xmpp.on("auth:failed", (data) => {
      this.logger.error("[XmppService] auth:failed", data);
      this._connectionStatus.next(XmppConnectionStatus.Disconnected);
    });

    this.xmpp.on("iq:set:blockList", (data) => {
      this.logger.error("[XmppService] iq:set:blockList", data);
    });

    this.xmpp.on("id:blocklist-push", (data) => {
      this.logger.info("[XmppService] blocklist-push", data);

      if (data.block && data.block.jids) {
        const jids = data.block.jids.map(v => v.bare);
        this.store.dispatch(new ConversationsBlockListAdd(jids));
      }
      if (data.unblock && data.unblock.jids) {
        const jids = data.unblock.jids.map(v => v.bare);
        this.store.dispatch(new ConversationsBlockListRemove(jids));
      }
    });

    this.xmpp.on("bosh:terminate", (data) => {
      this.logger.error("[XmppService][new.xmpp.onBoshTerminate]", data);
      this._connectionStatus.next(XmppConnectionStatus.Disconnected);
    });

    this.xmpp.on("stream:error", (data) => {
      this.logger.error(new Date().toISOString() + " [XmppService][sensor][stream:error]", data);

      // timeout on keep alive
      if (data.condition === "connection-timeout") {
        this.logger.error("[XmppService][stream:error]", "connection-timeout");

        this.runReconnectionTimer();
      }
      if ((data.condition === "policy-violation") && (data.text === "Failed to negotiate stream features: authentication failed")) {
        this.electronService.sendRemoteLog(new Date().toISOString() + " [XmppService][stream:error] " + JSON.stringify(data));
        this.broadcaster.broadcast("FORCED_LOGOUT");
      }
    });

    this.xmpp.on("stream:management:enabled", (data) => {
      this.logger.info("[XmppService][stream:management:enabled]", data);
    });

    this.xmpp.on("stream:management:failed", (data) => {
      this.logger.error("[new.xmpp.onStreamManagementFailed]", "WARNING!: we should not be there!", data);

      // WARNING: we should not be there!
      // If we spent more than 5 mins in background then we start a login process from scratch.

      // we spent to much time in background, so stream resumption is not possible.
      // so we disconnect and try to login again, from scratch
      this.xmpp.disconnect();
    });

    this.xmpp.on("disconnected", () => {
      // rationale:
      // when your network is still connected (local network) but your router is not,
      // the disconnect event happens after 90s of stale connection
      // for good measure add another 60s to be on the safe side
      const disconnectedAt = Math.floor( (new Date().getTime() / 1000) - 150).toString();
      // this.logger.error("[XmppService][sensor][disconnected] xmppdisconnectedAt: ", disconnectedAt);
      localStorage.setItem("lastXmppDisconnectAt", disconnectedAt);
      this._connectionStatus.next(XmppConnectionStatus.Disconnected);
    });

    this.xmpp.on("connected", (data, x) => {
      // DO NOT SET XMPP AS CONNECTED HERE BECAUSE IT'S NOT.
      // AUTHENTICATION IS DONE AFTER THIS
      // console.debug("[XmppService][connected]", data, x);
      this._connectionStatus.next(XmppConnectionStatus.Connected);
    });

    this.xmpp.on("carbon:received", (data) => {
      // PRASHANT_COMMENT not being called??
      if (data.carbonReceived && data.carbonReceived.forwarded && data.carbonReceived.forwarded.message
        && data.carbonReceived.forwarded.message.chatState) {
        // TODO: use redux here to keep track of currently typing users, instead of broadcaster.
        this.zone.run(() => {
          this.broadcaster.broadcast("onChatState", data.carbonReceived.forwarded.message);
        });
      }
    });

    this.xmpp.on("roster:ver", (data) => {
      this.logger.info("[xmpp.on.rosterVer]", data);
    });


    this.xmpp.on("message", (message) => {
      // const t1 = performance.now();
      this.logger.info("[XMPPService][xmppOnMessage]", message.type, message);


      if (message.error
        || message.mamResult
        || (message.muc && !message.muc.jid)
        || message.subject
        || message.carbonReceived
        || (message.vncTalkConference && message.vncTalkConference.eventType && (message.vncTalkConference.eventType === "self-reject"))) {
        return;
      }

      if (!!message.vncTalkConferenceScheduler && !!message.body && (message.body === " ")) {
        // this.logger.info("ssaMeetingCreateCheck: ", message);
        message.body = "";
      }

      if (message.mamItem) {
        const msg = message.mamItem.forwarded?.message;
        if (!!msg) {
          msg.timestamp = (!!msg.stamp && !!msg.stamp.stamp) ? (+msg.stamp.stamp) * 1000 : Date.now();
        }
        // this.logger.info("MAMCHECKPROC: ", msg.id, this.processingE2EMessages);
        if (msg.encrypted && !this.processingE2EMessages.includes(msg.id)) {
          this.store.select(getXmppOmemoInitState).pipe(take(1)).subscribe(omemoInitState => {
            this.logger.info("[XMPPService][on message][getXmppOmemoInitState]", omemoInitState);
          });
          this.store.select(getXmppOmemoInitState).pipe(filter(omemoInitState => omemoInitState > 1), take(1)).subscribe(() => {
            this.store.dispatch(new AddProcessingMessages([msg.id]));
            this.enqueueOrUseExisting(msg).subscribe(decryptedMessage => {
              if (Object.keys(decryptedMessage).length === 1) {
                this.logger.info("[XMPPService][on message] ignore - already processed", msg);
              } else {
                this.logger.info("[XMPPService][on message] decriptOrUseExistingMAM", msg.id, msg.body);
                this.processOnMamMessage(msg, true);
              }
              this.processingE2EMessages = this.processingE2EMessages.filter(v => this.processingE2EMessages.indexOf(v.id) === -1);
              this.store.dispatch(new RemoveProcessingMessage(message.id));
            });
          });
        } else {
          this.processOnMamMessage(msg, true);
        }

        return;
      }

      // extract if carbon
      const carbonMessage = message.carbonSent?.forwarded?.message;
      if (carbonMessage) {
        message = carbonMessage;
        if (message.stamp && message.stamp.stamp) {
          message.timestamp = (+message.stamp.stamp) * 1000;
        } else if (message.forwarded && message.forwarded.delay) {
          message.timestamp = new Date(message.forwarded.delay.stamp).getTime();
        } else if (message.delay && message.delay.stamp) {
          message.timestamp = new Date(message.delay.stamp).getTime();
        }
      }
      const isForwarded = !!carbonMessage;
      //

      // pass redminePreview as JSON string, cause of too many fields
      if (message.redminePreview) {
        message.redminePreview = JSON.parse(message.redminePreview.data);
      }

      // OMEMO chat transfer
      if (message.omemoHistoryExchangeSignal) {
        message.omemoHistoryExchangeSignal = JSON.parse(message.omemoHistoryExchangeSignal.data);
        if (!message.carbon && message.from.resource !== this.xmpp.jid.resource) {
          // this.logger.info("[XMPPService][processOnMessage] omemoHistoryExchangeSignal", message, this.xmpp.jid);

          // request
          if (message.omemoHistoryExchangeSignal.data.sendChatsHistoryIntention) {
            this.broadcaster.broadcast("onOMEMOSendChatsHistoryIntention", {...message.omemoHistoryExchangeSignal.data, from: message.from.full, fromBare: message.from.bare});
          // accept
          } else if (message.omemoHistoryExchangeSignal.data.acceptChatsHistoryIntention) {
            this.broadcaster.broadcast("onOMEMOAcceptChatsHistoryIntention", {from: message.from.full});
          // cancel
          } else if (message.omemoHistoryExchangeSignal.data.cancelChatsHistoryIntention) {
            this.broadcaster.broadcast("onOMEMOCancelChatsHistoryIntention");
          // p2p signaling
          } else if (message.omemoHistoryExchangeSignal.data.p2pDataChannelSignaling) {
            this.broadcaster.broadcast("onOMEMOP2PDataChannelSignaling", message.omemoHistoryExchangeSignal.data.p2pDataChannelSignaling);
          }
        }
        return;
      }

      if (message.encrypted && !this.processingE2EMessages.includes(message.id)) {
        this.store.select(getXmppOmemoInitState).pipe(take(1)).subscribe(omemoInitState => {
          this.logger.info("[XMPPService][on message][getXmppOmemoInitState]", omemoInitState);
        });
        this.store.select(getXmppOmemoInitState).pipe(filter(omemoInitState => omemoInitState > 1), take(1)).subscribe(() => {
          this.store.dispatch(new AddProcessingMessages([message.id]));
          const qmessage = { ...message, isForwarded: isForwarded };
          this.enqueueOrUseExisting(qmessage).subscribe(decryptedMessage => {
            if (Object.keys(decryptedMessage).length === 1) {
              this.logger.warn("[XMPPService][on message] ignore - already processed", message);
            } else {
              this.logger.warn("[XMPPService][on message] enqueueOrUseExisting", message.id, message.body);
              this.processOnMessage(decryptedMessage, isForwarded);
              this._onOmemoTrigger.next(Date.now());
            }
            this.processingE2EMessages = this.processingE2EMessages.filter(v => this.processingE2EMessages.indexOf(v.id) === -1);
            this.store.dispatch(new RemoveProcessingMessage(message.id));
          });
        });
      } else {
        this.processOnMessage(message, isForwarded);
      }

      // const t2 = performance.now();
      // this.logger.error(`[PERFORMANCE] [XMPPService] on message: took ${t2 - t1} milliseconds.`);
    });

    this.xmpp.on("muc:declined", (data) => {
      this.logger.error("muc:declined", data);
    });

    this.xmpp.on("muc:error", (data) => {
      // this.logger.error("muc:error", data);
      // https://redmine.vnc.biz/issues/30003052-1849 failed to join group chat: HANDLE ME!
      this._onMucError.next(data);
    });

    this.xmpp.on("muc:unavailable", () => {
      // this.logger.error("muc:unavailable", data);
    });

    this.xmpp.on("muc:destroyed", (data) => {
      this.logger.error("muc:destroyed", data);
    });

    // // fires when receive presence type=available'
    // this.xmpp.on("muc:available", (data) => {
    //   this.logger.info("muc:available", data);
    //   if (data.muc) {
    //     this.zone.run(() => {
    //       this._onMucAvailable.next({
    //         ...data,
    //         from: {...data.from},
    //         to: {...data.to},
    //         muc: {
    //           ...data.muc,
    //           jid: {...data.muc.jid}
    //         }
    //       });
    //     });
    //   }
    // });

    this.xmpp.on("chat:state", (msg) => {
      // TODO: use redux here to keep track of currently typing users, instead of broadcaster.
      // TODO: remove zone.run from anywhere we are subscribing to onChatState broadcast
      this.zone.run(() => {
        // this.logger.info("zone chat:state", msg);
        if ((msg.from.bare !== this.xmpp.jid.bare) && (msg.from.resource !== this.xmpp.jid.bare)) {
          this.broadcaster.broadcast("onChatState", msg);
        }
      });
    });

    this.xmpp.on("muc:invite", (data) => {

      this.logger.info("[XmppService.onMucInvite]", data);
      this._onMucInvite.next(data);
    });

    this.xmpp.on("muc:join", () => {
      // this.logger.info("[XmppService][on muc:join]", data);
    });

    this.xmpp.on("receipt", (data) => {
      this.logger.info("[XMPPService] on receipt", data);
      // this._onMessageReceipt.next(data.id);
      if (data.receipt !== null) {
        this._onMessageReceipt.next(data.receipt);
      } else {
        this.logger.info("[XMPPService] on receipt - empty received child?");
      }
    });

    this.xmpp.on("nick", (err, data) => {
      this.logger.info("[nick]", err, data);
    });

    this.xmpp.on("muc:subject", (data) => {
      const target = data.from.bare;
      const subject = data.subject;

      this.store.select(state => getConversationById(state , target))
      .pipe(take(1))
      .subscribe(conv => {
        // this.logger.info("[XMPPService] muc:subject", subject, conv.groupChatTitle);

        if (!conv) {
          const conv = ConversationUtil.createLocalConversation(target, subject);
          this.store.dispatch(new ConversationCreate(conv));
        } else {
          if (conv.groupChatTitle !== subject) {
            this.store.dispatch(new ConversationSubjectUpdate({target: target, subject: subject}));
          }
        }
      });
    });

    this.xmpp.on("presence", (presence, error) => {
      if (presence && !error) {
        // console.warn("[XMPPService][onPresence] raw", presence);
        // ignore own echo presences on join
        if (presence.type === "available" && presence.muc?.codes?.includes("110")) {
          if (presence.muc && presence.muc.affiliation && (presence.muc.affiliation === "none")) {
            this.logger.info("[XMPPService][onPresence] handle affiliation change ", presence);
          } else {
            // console.warn("[XMPPService][onPresence] ignore own echo presences on join", data.from.bare, data);
            return;
          }
        }

        // add IOM users to fix activity and avatar updates
        if ((presence.type === "subscribe") && (presence.from.domain !== this.xmpp.jid.domain)) {
          this.logger.info("[XMPPService][onPresence] onSubscribe: ", presence, this.xmpp.jid);
          this.xmpp.acceptSubscription(presence.from.bare);
          this.xmpp.subscribe(presence.from.bare);
        }

        if (!presence.muc && !presence.avatarId && !presence.nick) {
          // ignore presences we are not interested in
          return;
        }


        // ingore muc echo presences on join
        // https://xmpp.org/extensions/xep-0045.html#enter-pres
        if (presence.muc) {
          const currentTS = Math.round(Date.now() / 1000);

          const joinTS = this.roomsJoinedTime[presence.from.bare];
          // this.logger.info("[XMPPService][onpresence] MUC", presence.from.bare, currentTS, joinTS)
          if (joinTS && (currentTS - joinTS) < 10) {
            // console.warn("[XMPPService][onpresence] MUC echo on join ignore", presence.from.bare);
            return;
          }
        }
        //

        // this.logger.info("[XMPPService][onPresence]", data.from.bare, data, error);
        // spreading from and to to convert into simple JS objects from JID objects
        this._onPresence.next({...presence, from: {...presence.from}, to: {...presence.to}});
      }
    });

    this.xmpp.on("message:sent", (data) => {
      if (this.profile.useBOSHforWebclient) {
        this.handleOnMessageSent(data);
      }
    });
    this.xmpp.on("stanza:acked", (data) => {
      try {
        const jsonData = data.toJSON();
        this.handleOnMessageSent(jsonData);
      } catch (ex) {
        this.logger.sentryErrorLog("stanza:acked ex", ex);
      }

    });

    this.xmpp.on("message:error", () => {
      // this.logger.info("[XMPPService] message error", data, error);
      // TODO: we should be handling failed messages here and requeue them for sending,
      // but this is not getting called ever, not even when I try sending without internet.
    });

    this.xmpp.on("pubsub:event", msg => {
      if (!!msg.event?.updated?.node && (msg.event.updated.node === "urn:xmpp:omemo:1:devices")) {
        try {

            const publishedDevices = msg.event.updated.published[0].deviceList.devices;
            this.databaseService.storeDevices(msg.from.bare, [publishedDevices]).subscribe(() => {
              this.logger.info("PUBSUB omemo stored deviced ", msg.from.bare, this.xmpp.jid.bare);
              if (msg.from.bare === this.xmpp.jid.bare) {
                this.broadcaster.broadcast("omemo-devices-updated");
              }
            });

          const updatedDevice = msg.event.updated.published[0].id;
        } catch (error) {
          this.logger.error("XMPPSERVICE PUBSUB error ", error);
        }
      }
    });
  }

  private processOnMessage(message: any, isForwarded: boolean) {
    let timestamp = message.timestamp ? message.timestamp : new Date().getTime();

    if (message.stamp && message.stamp.stamp) {
      timestamp = (+message.stamp.stamp) * 1000;

      // upgrade 'last seen'
      const sentFrom = message.from.bare;
      this.store.select(state => getContactStatusById(state, sentFrom)).pipe(take(1)).subscribe(v => {
        let status = getUserStatusType(v).slug;
        if (status !== "online") {
          this._onStampReceived.next({jid: sentFrom, timestamp: timestamp});
        }
      });
    } else if (message.delay) {
      timestamp = new Date(message.delay.stamp).getTime();
    }

    const messageObject: Message = this.toMessage(message, timestamp, isForwarded);
    // this.logger.info("[XMPPService][processOnMessage]", messageObject, {...message});
    // this.logger.info("[XMPPService][processOnMessage] debug2 ", {...messageObject});
    if (message.expire && message.expire.seconds) {
      messageObject.expiry = +message.expire.seconds;
    }
    this._onMessage.next({...messageObject});

    if (!messageObject.replace && MessageUtil.isValidMessage(messageObject) ) {
      this.broadcaster.broadcast("onNewMessage", messageObject);
    }
  }

  private processOnMamMessage(message: any, isForwarded: boolean) {
    this.logger.info("[XMPPService][processOnMAM]", message);
    let timestamp = message.timestamp ? message.timestamp : new Date().getTime();

    if (message.stamp && message.stamp.stamp) {
      timestamp = (+message.stamp.stamp) * 1000;
    } else if (message.delay) {
      timestamp = new Date(message.delay.stamp).getTime();
    }

    const messageObject: Message = this.toMessage(message, timestamp, isForwarded);
    // this.logger.info("[XMPPService][processOnMessage]", messageObject, {...message});
    // this.logger.info("[XMPPService][processOnMessage] debug2 ", {...messageObject});
    if (message.expire && message.expire.seconds) {
      messageObject.expiry = +message.expire.seconds;
    }
    // this.logger.info("[XMPPService][processOnMAMnext]", messageObject, Date.now());
    this._onMamMessage.next({ ...messageObject });
  }

  private processOnResendSignal(signal: any) {
    this._onProcessOnResendSignal.next(signal);
  }

  enqueueOrUseExisting(msg: Message, returnStoredMsgIfExists: boolean = true): Observable<Message> {
    this.logger.info("[XMPPService][enqueueOrUseExisting]", msg.id, msg);

    const response = new BehaviorSubject<any>(null);

    this.isOMEMOMessageStored(msg).subscribe(isStored => {
      this.logger.info("[XMPPService][enqueueOrUseExisting] isOMEMOMessageStored", msg.id, isStored, msg.cannotDecrypt);

      const messageIsForLocalDevice = (this.omemoService.getLocalDeviceId() !== 0 && !!msg.encrypted?.header?.keys.find(k => (k.id === this.omemoService.getLocalDeviceId())));
      if (isStored || (msg.decryptAttempt > DECRYPT_ATTEMPT && (this.omemoService.getLocalDeviceId() !== 0) && !messageIsForLocalDevice)) {
        if (returnStoredMsgIfExists) {
          this.databaseService.getMessageById(msg.id).pipe(take(1)).subscribe(m => {
            let resMsg = { ...m };
            if ((m.body === "Encrypted message") && (!!m.html && !!m.html.body && (m.html.body !== "Encrypted message"))) {
              resMsg.body = m.html.body;
            }
            response.next(resMsg);
          });
        } else {
          response.next({ id: msg.id });
        }
      } else {
        this.databaseService.addMessageToDecryptQueue(msg).subscribe(() => {
          this.logger.info("[XMPPService][enqueueOrUseExisting] added to queue to decrypt", msg.id);
          response.next(msg);
          this._onOmemoTrigger.next(Date.now);
        });
      }
    });

    return response.asObservable().pipe(filter(v => !!v), take(1));
  }


  decriptOrUseExisting(msg: Message, returnStoredMsgIfExists: boolean = false): Observable<Message>{
    this.logger.info("[XMPPService][decriptOrUseExisting]", msg.id, msg);

    const response = new BehaviorSubject<any>(null);

    this.isOMEMOMessageStored(msg).subscribe(isStored => {
      this.logger.info("[XMPPService][decriptOrUseExisting] isOMEMOMessageStored",  msg.id, isStored, msg.cannotDecrypt);
      if (!!isStored) {
        this.logger.info("[XMPPService][decriptOrUseExistingDebug1] isStored ", isStored);
      } else {
        this.logger.info("[XMPPService][decriptOrUseExistingDebug1] isStored? ", isStored);
      }
      const messageIsForLocalDevice = (this.omemoService.getLocalDeviceId() !== 0 && !!msg.encrypted?.header?.keys.find(k => (k.id === this.omemoService.getLocalDeviceId())));
      if (isStored || (msg.decryptAttempt > DECRYPT_ATTEMPT && (this.omemoService.getLocalDeviceId() !== 0) && !messageIsForLocalDevice)) {
        if (returnStoredMsgIfExists) {
          this.databaseService.getMessageById(msg.id).pipe(take(1)).subscribe(m => {
            let resMsg = { ...m };
            if ((m.body === "Encrypted message") && (!!m.html && !!m.html.body && (m.html.body !== "Encrypted message"))) {
              resMsg.body = m.html.body;
            }
            response.next(resMsg);
          });
        } else {
          response.next({id: msg.id});
        }
      } else {
        this.decryptOMEMOMessage(msg).subscribe(res => {
          const target = (msg.from.bare === this.xmpp.jid.bare) ? msg.to.bare : msg.from.bare;
          this.store.select(state => getConversationById(state, target))
            .pipe(take(1))
            .subscribe(conv => {
              const msgDelay = Date.now() - msg.timestamp;
              // this.logger.info("[XMPPService][decriptOrUseExisting] DECRYPTED maybeSSA", res, msg, msgDelay, conv);
              if (msg.timestamp > conv.Timestamp) {
                if (res.type === "chat") {
                  this.broadcaster.broadcast("new_single_text", res);
                }
                if (res.type === "groupchat") {
                  this.broadcaster.broadcast("new_group_text", res);
                }
              }

              response.next(res);
            });
        }, err => {
          this.logger.info("[XMPPService][decriptOrUseExistingERROR", messageIsForLocalDevice, err, msg);
          this.databaseService.storeOmemoError(err, msg).subscribe();
          this.requestResend(msg);
          if ((this.omemoService.getLocalDeviceId() !== 0) && messageIsForLocalDevice) {
            setTimeout(() => {
              this.decryptOMEMOMessage(msg).subscribe(r => {
                this.logger.info("[XMPPService][decriptOrUseExisting] DECRYPTED", r);
                response.next(r);
              }, e => {
                this.removeOMEMOMaterialsWhenCantDecrypt(msg);
                response.next(msg);
              });
            }, 2000);
          }
          if (!!msg.decryptAttempt) {
            this.logger.info("[XMPPService][decriptOrUseExisting-msg.decryptAttempt] ERROR", messageIsForLocalDevice, msg.id, err);
          }
          this.removeOMEMOMaterialsWhenCantDecrypt(msg);
          response.next(msg);
        });
      }
    });

    return response.asObservable().pipe(filter(v => !!v), take(1));
  }

  isOMEMOMessageStored(msg: Message): Observable<boolean>{
    const response = new BehaviorSubject<any>(null);

    let existingMessage: Message;
    this.store.select(state => getMessageById(state, msg.id)).subscribe(message => {
      existingMessage = message;
    });
    if (!!existingMessage && !existingMessage.encrypted) {
      // re-enable to debug
      // this.logger.info("[XMPPService][isOMEMOMessageStored][decriptOrUseExistingDebug] existingMessage", existingMessage.id, existingMessage);
      response.next(true);
    } else {
      // re-enable to debug
      // this.logger.info("[XMPPService][isOMEMOMessageStored][decriptOrUseExistingDebug] existingMessage? ", existingMessage);
      this.databaseService.getMessageById(msg.id).subscribe((dbMsg: any) => {
        // this.logger.info("[XMPPService][isOMEMOMessageStored][decriptOrUseExistingDebug] -> db.getMessageById", dbMsg);
        if (dbMsg && (!dbMsg.encrypted || dbMsg.body !== "Encrypted message" || (!!dbMsg.html && !!dbMsg.html.body && (dbMsg.html.body !== "Encrpypted message")))) {
          response.next(true);
        } else {
          response.next(false);
        }
      });
    }

    return response.asObservable().pipe(filter(r => r !== null)).pipe(take(1));
  }

  private decryptOMEMOMessage(message: Message): Observable<Message> {
    const ts = (!!message.stamp?.stamp) ? message.stamp.stamp : message.timestamp;
    this.logger.info("[XMPPService][decryptOMEMOMessages] ", ts, message.id, message);

    return this.omemoService.decryptMessage(message);
  }

  removeOMEMOMaterialsWhenCantDecrypt(message: Message) {
    message.backupOMEMO = Object.assign({}, {encrypted: message.encrypted, encryption: message.encryption});
    // message.encrypted = null;
    // message.encryption = null;
    message.cannotDecrypt = true;
    if (!!message.decryptAttempt) {
      message.decryptAttempt = message.decryptAttempt + 1;
      if (message.decryptAttempt > DECRYPT_ATTEMPT) {
        message.encrypted = null;
        message.encryption = null;
        message.body = "Can't decrypt the message - not intended for current device";
      }
    } else {
      message.decryptAttempt = 1;
      message.body = "Encrypted message";
    }

    if (message.type === "chat") {
      this.broadcaster.broadcast("new_single_text", message);
    }
    if (message.type === "groupchat") {
      this.broadcaster.broadcast("new_group_text", message);
    }


    return message;
  }

  private handleOnMessageSent(data: any): void {
    if (["chat", "groupchat", "normal"].indexOf(data.type) !== -1 && !data.chatState && !data.muc && data.from && data.from.bare) {
      if (data.id) {
        this.zone.run(() => {
          CommonUtil.removeMessageFromPending(data.id);

          if (data.attachment || data.vncTalkBroadcast) {
            this.logger.info("[XmppService][handleOnMessageSent] toMessage", data.id, data);
            const timestamp = this.datetimeService.getCorrectedLocalTime();
            const msg = this.toMessage(data, timestamp);
            this._onMessage.next(msg);
          }
          if (data.replace) {
            if (data.body?.trim() === "") {
              this._onDeleteMessage.next(data.replace.id);
            } else {
              this.logger.info("[XmppService][handleOnMessageSent] replace message", data.id, data);
              const timestamp = this.datetimeService.getCorrectedLocalTime();
              const msg = this.toMessage(data, timestamp);
              this.store.dispatch(new MessageUpdateAction({ id: data.id, changes: { body: msg.body } }));

              this.databaseService.createOrUpdateMessages([msg], msg.from.bare);
            }
          }
        });
      }
    }
  }

  private handleOnMessageDeliveredToServer(f, t){
    const messageId = f;

    let msg;
    this.store.select(state => getMessageById(state, messageId)).pipe(take(1)).subscribe(message => msg = message);

    // preserve ms if same
    const timestamp = msg ? (t === Math.floor(msg.timestamp / 1000) ? msg.timestamp : t * 1000) : t * 1000;

    this.logger.info("[XmppService][handleOnMessageDeliveredToServer]", f, t, timestamp, msg && msg.id);

    if (msg) {
      const message = this.toMessage(msg, timestamp);

      this.zone.run(() => {
        this._onMessage.next(message);
      });
    }
    // else - ignore, e.g this is a 'startFile' message
  }

  private onSessionStarted() {
    this.logger.info("[XmppService][onSessionStarted]", this.xmpp.jid, this.xmpp);

    this.store.dispatch(new XmppSession({...this.xmpp.jid}));

    this._connectionStatus.next(XmppConnectionStatus.SessionStarted);

    this.xmpp.updateCaps();
    this.xmpp.enableCarbons();

    if (!this.xmpp.jid.avatarData) {
      this.updateContactFromVCard(this.xmpp.jid.bare); // test
    }

    this.getBlockList();

    this.sendPresence();

    // TODO:  what is that?
    if (localStorage.getItem("vncPeerJid") !== null && localStorage.getItem("vncEventType") !== null
      && (localStorage.getItem("vncEventType") === "groupchat" || localStorage.getItem("vncEventType") === "chat")) {
      this.broadcaster.broadcast("startChat", localStorage.getItem("vncPeerJid"));
      localStorage.removeItem("vncPeerJid");
      localStorage.removeItem("vncEventType");
    }

    // console.debug(`[XmppService][onSessionStarted] current client = ${this.xmpp.jid}`);
    this.initOmemo();
  }

  private onSessionResumed() {
    this.logger.info("[XmppService][onSessionResumed]", this.xmpp.jid, this.xmpp);

    this.store.dispatch(new XmppSession({...this.xmpp.jid}));

    this._connectionStatus.next(XmppConnectionStatus.SessionStarted);

    this.xmpp.updateCaps();
    this.xmpp.enableCarbons();

    this.sendPresence();
  }

  public getJoinedRooms() {
    if (!this.xmpp) {
      return [];
    }

    return Object.keys(this.xmpp.joinedRooms);
  }


  public sendMessage(target: string, message: any, expire?: number) {
    if (!this.xmpp) {
      return;
    }

    const msg = {...message, to: target, from: this.xmpp.jid};
    if (msg.type && msg.type === "groupchat") {
      msg["nick"] = this.xmpp.jid.local;
    }
    if (msg.vncTalkConference) {
      msg.vncTalkConference.from = this.xmpp.jid.bare;
    }
    if (msg.originalMessage) {
      delete msg.attachment;
    }
    // ToDo: this should not be required
    // later for groupchat?
    if (msg.type === "chat" && msg.body) {
      msg.requestReceipt = this.sendReceipt;
    }
    if (!!expire && (expire > 300)) {
      msg.expire = {
        seconds: Math.floor((msg.timestamp + expire) / 1000)
      };
      msg.expiry = Math.floor((msg.timestamp + expire) / 1000);
    }
    this.logger.info("[XmppService][sendMessage] to " + target, msg);


    if (msg.chatState && !msg.body) {
      msg["noStore"] = true;
      // this.logger.info("[XmppService][sendMessage] chatstate to adding no-store", msg);
    }

    if (target.indexOf("@") === -1) {
      return msg;
    }

    // pass redminePreview as JSON string, cause of too many fields
    if (msg.redminePreview) {
      msg.redminePreview = {data: JSON.stringify(msg.redminePreview)};
    }

    if (msg.omemoHistoryExchangeSignal) {
      msg.omemoHistoryExchangeSignal = {data: JSON.stringify(msg.omemoHistoryExchangeSignal)};
    }
    delete msg.$body;
    if (!msg.body){
      this.xmpp.sendMessage(msg);
      return msg;
    }

    // no need to encryp action messages, deleted message and call signals
    if (this.isOmemoChat(target) && !MessageUtil.isActionMessage(msg) && msg.body !== "" && !msg.replace) {
      this.databaseService.setLastOmemoActiveTS(target).subscribe(() => {
        if (msg.type === "groupchat"){
          this.store.select(state => getConversationMembers(state, target)).pipe(take(1)).subscribe(members => {
            this.logger.info("[XmppService][sendMessage] getConversationMembers", target, members, this.xmpp.jid.bare);
            this.store.select(getXmppOmemoInitState).pipe(filter(omemoInitState => omemoInitState > 1), take(1)).subscribe(() => {
              this.omemoService.sendMessage(msg, members);
            });
          });
        } else {
          this.omemoService.sendMessage(msg);
        }
      });
    } else {
      this.xmpp.sendMessage(msg);
    }

    if (msg.body === " ") {
      this.logger.info("ssaScheduleCheck msg: ", msg);
      msg.body = "";
    }

    return msg;
  }

  public sendMessageToMyself() {

  }

  public sendSignalToMyself(signal: any) {
    if (!this.xmpp) {
      return;
    }
    const msg = {type: "normal", signal: signal, to: this.xmpp.jid.bare, from: this.xmpp.jid,  noStore: true};
    this.logger.info("sendSignalToMyself", msg);
    this.xmpp.sendMessage(msg);
  }

  public sendSignalToTarget(signal: any) {
    if (!this.xmpp) {
      return;
    }
    const isGroupchat = CommonUtil.isGroupTarged(signal.target);
    const msg = {type: isGroupchat ? "groupchat" : "normal", signal: signal, to: signal.target, from: this.xmpp.jid,  noStore: true};
    this.xmpp.sendMessage(msg);
  }

  public sendSignalToOmemoTarget(signal: any) {
    if (!this.xmpp) {
      return;
    }
    const isGroupchat = CommonUtil.isGroupTarged(signal.target);
    const msg = { type: isGroupchat ? "groupchat" : "normal", signal: signal, to: signal.target, from: this.xmpp.jid };
    this.omemoService.sendMessage(msg, [signal.target, this.xmpp.jid.bare], true);
  }

  private isOmemoChat(target: string): boolean {
    let isOmemo = false;

    this.store.select(state => getConversationById(state, target))
      .pipe(take(1))
      .subscribe(conv => {
        isOmemo = conv && conv.encrypted;
      });

    return isOmemo;
  }

  public leave(target: string, eventType?: string) {
    this.logger.info("[XmppService][leave]", target, eventType);

    this.xmpp.leaveRoom(target, this.xmpp.jid.bare);

    if (eventType) {
      const from = this.xmpp.jid.bare;
      const to = this.xmpp.jid.bare;
      let msg: any = {to, from, body: "", type: "normal", time: new Date().getTime()};
      msg.vncTalkMuc = {
        from,
        to,
        conferenceId: target,
        eventType: eventType
      };
      this.xmpp.sendMessage(msg);
    }
  }

  private getBlockList() {
    this.logger.info("[XmppService][getBlockList]");

    this.xmpp.getBlocked((err, data) => {
      if (err === null && data && data.blockList) {
        // PRASHANT_COMMENT see why store is not receiving JID Object
        if (data.blockList.jids) {
          this.store.dispatch(new ConversationBlockListIndex(data.blockList.jids.map(j => j.bare)));
        }
      } else {
        // this.logger.error("[XmppService][updateBlockList] error", err);
      }
    });
  }

  private toMessage(msg: {
    id: string, to: JID, from: JID, type: string, body: string, hasSent?: boolean, isDeleted?: boolean, attachment?: any, location?: any, carbon?: any, delay?: any, vncTalkConference
      ?: any, encrypted?: boolean
  }, timestamp: number | null = null, isForwarded = false): Message {

    // console.trace("toMessage", msg);

    const message: any = {
      ...msg,
      timestamp,
      isForwarded
    };

    if (!message.id) {
      let body: string;

      if (message.attachment) {
        body = message.attachment.url;
      } else if (message.location) {
        body = message.location.lat + message.location.lng;
      } else {
        body = message.body;
      }

      const sender = message.type === "groupchat" ? message.from.resource || message.from.bare : message.from.bare;

      if (this.electronService.isElectron) {
        message.id = this.electronService.md5(sender + body + message.timestamp);
      } else {
        message.id = md5(sender + body + message.timestamp);
      }
    }

//    if (msg.delay) {
//      this.logger.info("toMessage delay: ", msg.delay);
//      let delay = msg.delay;
      // message["delay"] = msg.delay;
//      message.delay = msg.delay;
//    }
//    this.logger.info("toMessage: ", message);
    return message;
  }

  public createRoom(name?: string, isTemporary?: boolean): Observable<string> {
    this.logger.info("[XmppService][createRoom]", name, this.conferenceDomain);

    const response = new Subject<string>();

    if (!this.conferenceDomain) {
      // TODO: need to create a consistent error model for whole app.
      response.error({error: "conferenceDomain not set"});
    }

    if (name) {
      this.getUniqueNameFromSubject(name, bare => {
        this.zone.run(() => {
          this.logger.info("[XmppService][createRoom] getUniqueNameFromSubject", bare);
          if (!!bare) {
            response.next(bare);
          } else {
            response.error({error: "Room with this name already exists"});
          }
        });
      }, isTemporary);
    } else {
      this.getUniqueName(bare => {
        this.zone.run(() => {
          this.logger.info("[XmppService][createRoom] getUniqueName", bare);
          response.next(bare);
        });
      }, isTemporary);
    }

    return response.asObservable();
  }

  public createMeetingRoom(name?: string, quickMeeting?: boolean): Observable<string> {
    this.logger.info("[XmppService][createMeetingRoom]", name, this.conferenceDomain);

    const response = new Subject<string>();

    if (!this.conferenceDomain) {
      // TODO: need to create a consistent error model for whole app.
      response.error({error: "conferenceDomain not set"});
    }

    if (name) {
      this.getUniqueMeetingNameFromSubject(name, quickMeeting, (bare) => {
        this.zone.run(() => {
          this.logger.info("[XmppService][createRoom] getUniqueMeetingNameFromSubject", bare);
          if (!!bare) {
            response.next(bare);
          } else {
            response.error({error: "Room with this name already exists"});
          }
        });
      });
    } else {
      this.getUniqueMeetingName(quickMeeting, (bare) => {
        this.zone.run(() => {
          this.logger.info("[XmppService][createRoom] getUniqueMeetingName", bare);
          response.next(bare);
        });
      });
    }

    return response.asObservable();
  }

  private getUniqueNameFromSubject(name: string, cb: (bare) => void, isTemporary?: boolean) {
    this.logger.info("[getUniqueNameFromSubject]", name);
    name = name.replace(/[^a-z0-9]/gi, "");
    this.logger.info("[getUniqueNameFromSubject1]", name);
    if (name.length === 0) {
      name = "a";
    }
    try {
      let bare = punycode.toASCII(name.toLowerCase().trim().replace(/\s+/g, "__")) + "_" + CommonUtil.randomId(12) + "_talk@" + this.conferenceDomain;
      if (isTemporary) {
        bare = punycode.toASCII(name.toLowerCase().trim().replace(/\s+/g, "__")) + "_" + CommonUtil.randomId(12) + "_temporary_group@" + this.conferenceDomain;
      }
      this.logger.info("[getUniqueNameFromSubject] bare:", bare);
      this.xmpp.getUniqueRoomName(bare, (err, res) => {
        if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
          // name we generated doesn't exists
          cb(err.from.bare);
        } else if (err.error && err.error.condition === "jid-malformed") {
          this.getUniqueName(cb, isTemporary);
        } else {
          // name we generated already exists
          this.getUniqueNameFromSubject(name, cb);
        }
      });
    } catch (ex) {
      this.getUniqueName(cb, isTemporary);
      this.logger.error("[XmppService][getUniqueNameFromSubject] err", ex);
    }
  }

  private getUniqueMeetingNameFromSubject(name: string, quickMeeting: boolean, cb: (bare) => void) {
    this.logger.info("[getUniqueMeetingNameFromSubject]", name);
    try {
      name = name.replace(/[^a-z0-9]/gi, "");
      if (name.length === 0) {
        name = "a";
      }
      const randomId = CommonUtil.randomId(12);
      const newName = `${name}_${randomId}`;
      let bare = punycode.toASCII(newName.toLowerCase().trim().replace(/\s+/g, "__")) + "_talk_meeting@" + this.conferenceDomain;
      if (quickMeeting) {
        bare = punycode.toASCII(newName.toLowerCase().trim().replace(/\s+/g, "__")) + "_quick_meeting@" + this.conferenceDomain;
      }
      if (!this.xmpp) {
        alert("XMPP is not connected!");
        return;
      }
      this.logger.info("[getUniqueMeetingNameFromSubject] bare", bare);
      this.xmpp.getUniqueRoomName(bare, (err, res) => {
        this.logger.info("[getUniqueMeetingNameFromSubject]", err);
        if (err.error && err.error.condition === "jid-malformed") {
          alert("jid-malformed");
          return;
        }
        if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
          // name we generated doesn't exists
          cb(err.from.bare);
        } else {
          // name we generated already exists
          this.getUniqueMeetingNameFromSubject(name, quickMeeting, cb);
        }
      });
    } catch (ex) {
      this.getUniqueMeetingName(quickMeeting, cb);
      this.logger.error("[XmppService][getUniqueMeetingNameFromSubject] err", ex);
    }
  }

  private getUniqueName(cb: (bare) => void, isTemporary?: boolean) {
    let name = CommonUtil.randomId(12) + "_talk" + "@" + this.conferenceDomain;
    if (isTemporary) {
      name = CommonUtil.randomId(12) + "_temporary_group" + "@" + this.conferenceDomain;
    }
    this.xmpp.getUniqueRoomName(name, (err, res) => {
      if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
        // name we generated doesn't exists
        cb(err.from.bare);
      } else {
        // name we generated already exists
        this.getUniqueName(cb, isTemporary);
      }
    });
  }

  private getUniqueMeetingName(quickMeeting: boolean, cb: (bare) => void) {
    let name = CommonUtil.randomId(12) + "_talk_meeting" + "@" + this.conferenceDomain;
    if (quickMeeting) {
      name = CommonUtil.randomId(12) + "_quick_meeting" + "@" + this.conferenceDomain;
    }
    this.xmpp.getUniqueRoomName(name, (err, res) => {
      if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
        // name we generated doesn't exists
        cb(err.from.bare);
      } else {
        // name we generated already exists
        this.getUniqueMeetingName(quickMeeting, cb);
      }
    });
  }

  public logout() {
    this.logger.info("[XMPPService][logout]");

    if (this.networkSubscription$) {
      this.networkSubscription$.unsubscribe();
    }
    this.xmppLoggedOut = true;
    this.sentNickname = true;
    if (this.xmpp && this.xmpp.sessionStarted) {
        this.xmpp.disconnect();
    }
  }

  public updateRoomHistoryLength(target: string, length: string): Observable<any> {
    this.logger.info("[XMPPService][updateRoomHistoryLength] target", target);
    const response = new Subject();
    if (this.xmpp) {
      this.xmpp.getRoomConfig(target, (err, res) => {
        this.logger.info("[XMPPService][updateRoomHistoryLength] existingConfig res & err", res, err);

        if (!!res.mucOwner && !!res.mucOwner.form && !!!!res.mucOwner.form.fields) {
          let fields = res.mucOwner.form.fields;
          let updatedFields = [];
          for (let i = 0; i < fields.length; i++) {
            let newField = fields[i];
            if (fields[i].name === "muc#roomconfig_historylength") {
              newField.value = length;
            }
            if (fields[i].name === "muc#roomconfig_defaulthistorymessages") {
              newField.value = length;
            }
            updatedFields.push(newField);
          }
          this.logger.info("[XMPPService][updateRoomHistoryLength] newConfig: ", fields);
          this.xmpp.configureRoom(target, updatedFields, (err, res) => {
            if (err === null && res) {
              this.logger.info("[XMPPService][updateRoomHistoryLength] postConfig res: ", res);
              response.next(res);
            } else {
              response.next(null);
            }
          });
        } else {
          response.next(null);
        }

      });
    }

    return response.asObservable();
  }

  public getRoomConfig(target: string): Observable<ConversationConfig> {
    this.logger.info("[XMPPService][getRoomConfig] target", target);

    const response = new Subject();
    if (this.xmpp) {
      this.xmpp.getRoomConfig(target, (err, res) => {
        this.logger.info("[XMPPService][getRoomConfig] res & err", res, err);

        if (err === null && res) {
          this.zone.run(() => {
            if (res.mucOwner && res.mucOwner.form && res.mucOwner.form.fields) {
              response.next(this.parseXmppRawConfig(res.mucOwner.form.fields));
            } else {
              response.error(err);
            }
          });
        } else {
          response.error(err);
        }
      });
    }


    return response.asObservable();
  }

  public configureRoom(target: string, config?: ConversationConfig, historylength?:string): Observable<any> {
    const response = new Subject();

    const roomConfigForm = {
      fields: this.prepareRoomConfigForm(config, historylength)
    };

    this.logger.info("[XMPPService][configureRoom]", target, roomConfigForm);

    this.xmpp.configureRoom(target, roomConfigForm, (err, res) => {
      this.zone.run(() => {
        if (err) {
          response.error(err);
          this.logger.info(err);
        } else {
          response.next(res);
        }
      });
    });

    return response.asObservable();
  }

  private prepareRoomConfigForm(config?: ConversationConfig, historylength?:string) {
    // this.logger.info("[xmpp.service] prepareRoomConfigForm config: ", config);
    const mergedConfig = {persistent: 1,
                            isPublic: 1,
                          memberOnly: 0,
                               isE2E: 0,
                           ...config};

    // this.logger.info("[xmpp.service] prepareRoomConfigForm mergedConfig: ", mergedConfig);

    return [
      {name: "FORM_TYPE", value: "http://jabber.org/protocol/muc#roomconfig"},
      {name: "muc#roomconfig_persistentroom", value: mergedConfig.persistent.toString()},
      {name: "muc#roomconfig_changesubject", value: "1"},
      {name: "muc#roomconfig_publicroom", value: mergedConfig.isPublic.toString()},
      {name: "muc#roomconfig_roomname", value: ""},
      {name: "muc#roomconfig_moderatedroom", value: "0"},
      {name: "muc#roomconfig_membersonly", value: mergedConfig.memberOnly.toString()},
      {name: "muc#roomconfig_whois", value: mergedConfig.hidden === 1 ? "moderators" : "anyone"},
      {name: "muc#roomconfig_historylength", value: !!historylength ? historylength : "5"},
      {name: "muc#roomconfig_e2e", value: mergedConfig.isE2E.toString()}
    ];
  }

  private parseXmppRawConfig(fields: { name: string, value: any }[]): ConversationConfig {
    const roomName = fields.find(f => f.name === "muc#roomconfig_roomname")?.value;
    const persistent = fields.find(f => f.name === "muc#roomconfig_persistentroom")?.value;
    const isPublic = fields.find(f => f.name === "muc#roomconfig_publicroom")?.value;
    const memberOnly = fields.find(f => f.name === "muc#roomconfig_membersonly")?.value;
    const isE2E = fields.find(f => f.name === "muc#roomconfig_e2e")?.value;

    const response = {};

    if (roomName) {
      response["roomName"] = roomName;
    }

    response["persistent"] = persistent ? 1 : 0;
    response["isPublic"] = isPublic ? 1 : 0;
    response["memberOnly"] = memberOnly ? 1 : 0;
    response["isE2E"] = isE2E ? 1 : 0;

    return response;
  }

  public invite(target: string, newMembers: string[], reason = "1") {
    newMembers.map(m => {
      this.xmpp.invite(target, [{to: m, reason: reason}]);
    });
  }

  public kick(target: string, nick: string): Observable<any> {
    const response = new Subject<any>();
    this.xmpp.kick(target, nick, "none", (err, res) => {
      this.zone.run(() => {
        if (err) {
          response.error(err);
        }
        if (res) {
          response.next(res);
        } else {
          response.error("invalid response from xmpp");
        }
      });
    });
    return response.asObservable();
  }

  public uploadGroupAvatar(target: string, photo: any): Observable<any> {
    const response = new Subject<null>();

    this.xmpp.uploadGroupAvatar(target, photo, (err, res) => {
      this.zone.run(() => {
        if (err) {
          response.error(err);
        } else {
          response.next(res);
        }
      });
    });

    return response.asObservable();
  }

  public publishVCards(data: ContactInformation): Observable<any> {
    const response = new Subject<any>();
    this.xmpp.publishVCard(data, (err, res) => {
      this.logger.info("[publishVCard]", err, res);
      this.zone.run(() => {
        if (err) {
          response.error(err);
        }

        response.next(res);
      });
    });

    return response.asObservable();
  }

  public publishNick(nick: string): Observable<any> {
    const response = new Subject<any>();

    this.xmpp.publishNick(nick, (err, res) => {
      this.logger.info("[publishNick] xmpp", err, res);
      this.zone.run(() => {
        if (err) {
          response.error(err);
        }
        this.sendPresence(nick);
        response.next(res);
      });
    });

    return response.asObservable();
  }

  subscribe(bare) {
    this.xmpp.subscribe(bare);
  }

  unsubscribe(bare) {
    this.xmpp.unsubscribe(bare);
  }

  setSubject(target: string, newTitle: string) {
    this.xmpp.setSubject(target, newTitle);
  }

  public getDiscoItems(item: string): Observable<JID[]> {
    const response = new Subject<JID[]>();

    this.xmpp.getDiscoItems(item, "", (err, res) => {
      this.zone.run(() => {
        if (err) {
          this.logger.error("[XmppService][getDiscoItems] err", err);
          response.error(err);
        } else {
          this.logger.info("[XmppService][getDiscoItems]", item, res);

          if (res) {
            if (res.discoItems && res.discoItems.items) {
              response.next(res.discoItems.items.map((m) => m.jid));
            } else {
              response.next([]);
            }
          } else {
            response.error("no response from server");
          }
        }
      });
    });

    return response.asObservable();
  }

  public getDiscoInfo(jid: string): Observable<JID[]> {
    const response = new Subject<JID[]>();

    this.xmpp.getDiscoInfo(jid, "", (err, res) => {
      this.zone.run(() => {
        if (err) {
          this.logger.error("[XmppService][getDiscoInfo] err", err);
          response.error(err);
        } else {
          this.logger.info("[XmppService][getDiscoInfo]", jid, res);

          if (res) {
            if (res.discoInfo) {
              response.next(res.discoInfo);
            } else {
              response.next([]);
            }
          } else {
            response.error("no response from server");
          }
        }
      });
    });

    return response.asObservable();
  }

  public requestSlot(fileName: string, fileSize: number): Observable<UploadSlot> {
    const response = new Subject<UploadSlot>();
    this.xmpp.requestSlot({
      filename: fileName,
      size: fileSize
    }, (err, res) => {
      this.logger.info("[XmppService][requestSlot]", err, res);
      this.zone.run(() => {
        if (res && res.httpupload) {
          response.next(res as UploadSlot);
        } else {
          response.error(err);
        }
      });
    });

    return response.asObservable();
  }

  private _setUpMUCRegisterModule(): void {
    this.xmpp.disco.addFeature("jabber:iq:register");

    this.xmpp.unregisterMemberList = (conversationTarget, cb) => {
      this.logger.info("[XmppService][unregister] called for target: ", conversationTarget);
      return this.xmpp.sendIq({
        from: this.xmpp.jid.bare,
        to: conversationTarget,
        type: "set",
        query: {xmlns: "xmpp:vnctalk:unregister"}
      }, cb);
    };
  }

  // we have only 'unregister' flow, since a registration is done automatically
  // at the backend side and send an invite message
  public unregisterMemberList(conversationTarget: string): Observable<any> {
    const response = new Subject<any>();
    this.xmpp.unregisterMemberList(conversationTarget, (err, res) => {
      this.zone.run(() => {
        if (res) {
          response.next(res);
        } else {
          response.error(err);
        }
      });
    });

    return response.asObservable();
  }

  public getLastActivity(bare: string): Observable<any> {
    // this.logger.info("[XMPPService][getLastActivity] bare", bare);

    const response = new Subject<any>();
    if (this.xmpp) {
      this.xmpp.getLastActivity(bare, (err, res) => {
        if (res) {
          // this.logger.info("[XMPPService][getLastActivity] res", res.lastActivity, bare);
          response.next(res);
        } else {
          this.logger.error("[XMPPService][getLastActivity] error", bare, err);
          response.error(err);
        }
      });
    } else {
      response.next("0");
    }

    return response.asObservable();
  }

  private _setupLastActivityModule(): void {
    this.xmpp.stanzas.add = function (ParentJXT, fieldName, field) {
      field.enumerable = true;
      field.configurable = true;
      try {
        Object.defineProperty(ParentJXT.prototype, fieldName, field);
      } catch (error) {
        this.logger.error("defineProperty error", fieldName, error);
      }
    };
    this.xmpp.disco.addFeature("jabber:iq:last");
    let NS = "urn:ietf:params:xml:ns:xmpp-stanzas";
    let types = this.xmpp.stanzas.utils;
    let Req = this.xmpp.stanzas.define({
      name: "query",
      element: "query",
      namespace: NS,
      fields: {
        "xmlns": types.attribute("xmlns"),
        "remove": types.textSub("", "remove")
      }
    });

    this.xmpp.stanzas.withIQ((Iq) => {
      this.xmpp.stanzas.extend(Iq, Req);
    });

    this.xmpp.getLastActivity = (bare, cb) => {
      return this.xmpp.sendIq({
        from: this.xmpp.jid.full,
        to: bare,
        type: "get",
        query: {xmlns: "jabber:iq:last"}
      }, cb);
    };
  }

  public getLastActivityBatch(bareJids: string[]): Observable<any> {
    const response = new Subject<any>();
    // this.logger.info("[XMPPService][getLastActivityBatch]1");
    if (this.xmpp) {
            // this.logger.info("[XMPPService][getLastActivityBatch]2", bareJids);
      this.xmpp.getLastActivityBatch(bareJids, (err, res) => {
        // this.logger.info("[XMPPService][getLastActivityBatch] res", res);
        if (res) {
          response.next(res);
        } else {
          this.logger.error("[XMPPService][getLastActivityBatch] error", bareJids, err);
          response.error(err);
        }
      });
    } else {
      response.next({});
    }

    return response.asObservable();
  }

  private _setupLastActivityBatchModule(): void {
    this.xmpp.disco.addFeature("jabber:iq:batch");

    const types = this.xmpp.stanzas.utils;

    const Req = this.xmpp.stanzas.define({
      name: "query",
      element: "query",
      namespace: "jabber:iq:batch",
      fields: {
        xmlns: types.attribute("xmlns"),
        jids: types.multiTextSub(null, "jid"),
      }
    });

    this.xmpp.stanzas.withIQ((Iq) => {
      this.xmpp.stanzas.extend(Iq, Req);
    });

    this.xmpp.getLastActivityBatch = (bareJids, cb) => {
      return this.xmpp.sendIq({
        from: this.xmpp.jid.full,
        type: "get",
        query: {xmlns: "jabber:iq:batch", jids: bareJids}
      }, cb);
    };
  }

  private _setUpHTTPUploadModule(): void {
    this.xmpp.disco.addFeature("urn:xmpp:http:upload");
    let NS = "urn:xmpp:http:upload";
    let types = this.xmpp.stanzas.utils;
    let Req = this.xmpp.stanzas.define({
      name: "request",
      element: "request",
      namespace: NS,
      fields: {
        "xmlns": types.attribute("xmlns"),
        "filename": types.textSub(NS, "filename"),
        "size": types.textSub(NS, "size"),
        "content-type": types.textSub(NS, "content-type")
      }
    });

    this.xmpp.stanzas.withIQ((Iq) => {
      this.xmpp.stanzas.extend(Iq, Req);
    });
    this.xmpp.requestSlot = (opts, cb) => {
      return this.xmpp.sendIq({
        from: this.xmpp.jid.bare,
        to: this.xmpp.jid.domain,
        type: "get",
        request: Object.assign({
          xmlns: "urn:xmpp:http:upload"
        }, opts || {})
      }, cb);
    };
  }

  private _setUpCustomStanzaHadler(): void {
    this.xmpp.off("stream:data"); // if we do not off then it duplicates the event
    this.xmpp.on("stream:data", (data) => {
      let json = data.toJSON();
      // this.logger.info("[_setUpCustomStanzaHadler]", json);
      if (json.block && json.block.jids) {
        const jids = json.block.jids.map(v => v.bare);
        this.store.dispatch(new ConversationsBlockListAdd(jids));
        // this.logger.info("[ConversationsBlockListAdd]", jids);
      } else if (json.unblock && json.unblock.jids) {
        const jids = json.unblock.jids.map(v => v.bare);
        this.store.dispatch(new ConversationsBlockListRemove(jids));
        // this.logger.info("[ConversationsBlockListRemove]", jids);
      }
      try {
        this.xmpp.emit(data._eventname || data._name, json);
      } catch (error) {
        if ((data._name === "iq") && (!!json.roster)) {
          // do nothing
        } else {
          // this.logger.error("emit error", error, data._eventname || data._name, json);
        }
      }

      // IQ
      if (data._name === "iq") {
        // this.logger.info("[XmppService][_setUpCustomStanzaHadler] iq", json);

        // handle message server delivery response
        if (json.t && json.f) {
          this.handleOnMessageDeliveredToServer(json.f, json.t);
          return;
        }

        json._xmlChildCount = 0;
        data.xml.childNodes.forEach((child) => {
          if (child.nodeType === 1) {
            json._xmlChildCount += 1;
          }
        });

        if (data.xml && data.xml.children && data.xml.children[0]){
          const firstChildNodeName = data.xml.children[0].nodeName;
          const firstChildXmlns = data.xml.children[0].attrs.xmlns;

          // File upload request slot response
          if (firstChildNodeName === "slot") {
            this.xmpp.emit("vnc:httpupload", data.xml);

            try {
              json.httpupload = {
                get: data.xml.children[0].children[0].children[0],
                put: data.xml.children[0].children[1].children[0]
              };
            } catch (e) {
              this.logger.sentryErrorLog("[XMPPService][onStream:data]", e);
              this.logger.error("[XMPPService][onStream:data]", e);
            }

          // Last Activity response
          } else if (firstChildNodeName === "query" && data.xml.children[0].attrs.seconds) {
            try {
              json.lastActivity = data.xml.children[0].attrs.seconds;
            } catch (e) {
              this.logger.sentryErrorLog("[XMPPService][onStream:data]", e);
              this.logger.error("[XMPPService][onStream:data]", e);
            }

          // Last Activity Batch response
          } else if (firstChildNodeName === "query" && firstChildXmlns === "jabber:iq:last"
            && data.xml.children[0].children && data.xml.children[0].children[0] && data.xml.children[0].children[0].nodeName === "result") {

            try {
              json.lastActivityBatchResults = {};
              for (let resSubelement of data.xml.children[0].children) {
                json.lastActivityBatchResults[resSubelement.attrs.jid] = {seconds: resSubelement.attrs.seconds,
                                                                            photo: resSubelement.attrs.photo};
              }
            } catch (e) {
              this.logger.error("[XMPPService][onStream:data]", e);
            }
          }
        }

      // Message
      } else if (data._name === "message" || data._name === "presence" || data._name === "iq") {
        this.xmpp.sm.handle(json);
        this.xmpp.emit("stanza", json);

      // SM ask
      } else if (data._name === "smAck") {
        return this.xmpp.sm.process(json);

      // SM request
      } else if (data._name === "smRequest") {
        return this.xmpp.sm.ack();
      }

      if (json.id) {
        this.xmpp.emit("id:" + json.id, json);
        this.xmpp.emit(data._name + ":id:" + json.id, json);
      }
    });
  }

  getPrivateDocuments(): Observable<any> {
    this.logger.info("[XMPPService][getPrivateDocuments] options");
    return this.authService.getPrivateDocuments();
  }

  // called in 3 cases:
  // - general app settings updates
  // - Group chat settings updates
  //
  updatePrivateDocuments(options: any): Observable<any> {
    this.logger.info("[XMPPService][updatePrivateDocuments] options", options);
    localStorage.removeItem("privateDocsFromServer");

    const response = new Subject<any>();

    this.getPrivateDocuments().subscribe(documents => {
      documents = documents || {};
      const defaultDocuments = CommonUtil.getAppSettings(documents);
      this.logger.info("[XMPPService][updatePrivateDocuments] defaultSettings: ", defaultDocuments);
      this.logger.info("[XMPPService][updatePrivateDocuments] documents: ", documents);

      if (!documents.notifyOptions || !documents.desktopNotifyOptions) {
        documents = defaultDocuments;
      }
      const mergedDocuments = {...documents, ...options};

      // remove old obsolete params
      delete mergedDocuments.preferableCameraId;
      delete mergedDocuments.preferableMicId;
      delete mergedDocuments.preferableAudioOutputId;
      //
      if (mergedDocuments.preferableMediaDevices && typeof mergedDocuments.preferableMediaDevices !== "string") {
        mergedDocuments.preferableMediaDevices = JSON.stringify(mergedDocuments.preferableMediaDevices);
      }

      this.logger.info("[XMPPService][updatePrivateDocuments] mergedDocuments", mergedDocuments, options);

      if (this.xmpp && this.xmpp.sessionStarted) {
        this.xmpp.setPrivateDocuments(mergedDocuments, (err, res) => {
          if (!err) {
            this.logger.info("[XMPPService][updatePrivateDocuments] success", res);

            // need to parse it back after 'xmppService.updatePrivateDocuments' call
            if (mergedDocuments.preferableMediaDevices && typeof mergedDocuments.preferableMediaDevices === "string") {
              mergedDocuments.preferableMediaDevices = JSON.parse(mergedDocuments.preferableMediaDevices);
            }

            response.next(mergedDocuments);
          } else {
            this.logger.error("[XMPPService][updatePrivateDocuments] failed", err);
            response.error(err);
          }
        });
      } else {
        setTimeout(() => {
          this.xmpp.setPrivateDocuments(mergedDocuments, (err, res) => {
            if (!err) {
              this.logger.info("[XMPPService][updatePrivateDocuments] success", res);

              // need to parse it back after 'xmppService.updatePrivateDocuments' call
              if (mergedDocuments.preferableMediaDevices && typeof mergedDocuments.preferableMediaDevices === "string") {
                mergedDocuments.preferableMediaDevices = JSON.parse(mergedDocuments.preferableMediaDevices);
              }

              response.next(mergedDocuments);
            } else {
              this.logger.error("[XMPPService][updatePrivateDocuments] failed", err);
              response.error(err);
            }
          });
        }, 3000);
      }
    }, error => {
      this.logger.error("[XMPPService][updatePrivateDocuments] getPrivateDocuments failed", error);
      response.error(error);
    });

    return response.asObservable();
  }

  markActive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      try {
        this.xmpp.markActive();
      } catch (e) {
        this.logger.sentryErrorLog("markActive failed with: ", e);
        this.logger.info("markActive failed with: ", e);
      }

    }
  }

  markInactive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      this.logger.info("[XmppService][markInactive]");

      this.LastInactiveTime = Date.now() / 1000;
      this.xmpp.LastInactiveTime = Date.now();
      try {
        this.xmpp.markInactive();
        setTimeout(() => {
          this.logger.info("[XmppService][markInactive] cancelconn");
          this.xmpp.sm.failed();
          this.xmpp.disconnect(true);
          this.store.dispatch(new SetXmppConnection(false));
        }, 45000);
      } catch (e) {
        this.logger.sentryErrorLog("[XmppService][markInactive]", e);
        this.logger.error("[XmppService][markInactive]", e);
      }
    }
  }

  enableKeepAlive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      const opts = {
        timeout:  30,
        interval: 45
//        timeout: environment.xmppTimeout,
//        interval: environment.xmppTimeout / 2
      };

      this.logger.info("[XmppService][enableKeepAlive]", opts);

      this.xmpp.enableKeepAlive(opts);
    }
  }

  disableKeepAlive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      this.logger.info("[XmppService][disableKeepAlive]");
      this.xmpp.disableKeepAlive();
    }
  }

  triggerReconnect(): void {
    this.logger.info("[xmppService][sensor][triggerReconnect]");
    if (this.xmpp) {
      this.xmpp.sm.failed();
      this.xmpp.disconnect(true);
      this.store.dispatch(new SetXmppConnection(false));
      setTimeout(() => {
        this.runReconnectionTimer();
      }, 5000);
    }
  }

  public setRoomAffiliation(room: string, jid: string, affiliation: string): Observable<any> {
    this.logger.info("[setRoomAffiliation]", room, jid, affiliation);
    const response = new Subject<any>();
    let reason = affiliation === "owner" ? "change_owner" : "kick";
    if (affiliation === "member" || affiliation === "admin" || affiliation === "moderator") {
      reason = "set_role";
    }
    this.xmpp.setRoomAffiliation(room, jid, affiliation, reason, (err, data) => {
      this.logger.info("[setRoomAffiliation] xmpp", room, jid, affiliation, err, data);
      if (err === null) {
        response.next(data);
      } else {
        response.error(err);
      }
    });
    return response.asObservable();
  }

  public addBareToRoster(bare: string) {
    if (bare.indexOf("@conference") === -1) {
      try {
        this.xmpp.acceptSubscription(bare);
        this.xmpp.subscribe(bare);
      } catch (error) {
        this.logger.info("[xmpp.service][addBareToRoster] error: ", error);
      }
    }
  }

  public getIsConnectedXMPP() {
    return this.isXmppConnected;
  }

  public tryToForceReconnect() {
    this.tryToReconnect();
  }

  removeOmemoSubscribers(list: string[]) {
    this.logger.info("getPubSubDeviceSubs list: ", list);
    let delta = [];
    let deltaKeepBare = [];
    for (let i = 0; i < list.length; i++) {
        this.logger.info("getPubSubDeviceSubs processing jid: ", list[i]);
        delta.push( {jid: list[i], type: "none"} );
        if (deltaKeepBare.indexOf(list[i].split("/")[0]) < 0) {
          deltaKeepBare.push(list[i].split("/")[0]);
        }
    }
    this.logger.info("getPubSubDeviceSubs deltaKeepBare: ", deltaKeepBare);
    if (deltaKeepBare.length > 0) {
      for (let i = 0; i < deltaKeepBare.length; i++) {
        if (this.getUserJid() !== deltaKeepBare[i])  {
          delta.push({jid: deltaKeepBare[i], type: "subscribed"});
        }
      };
    }
    if (delta.length > 0) {
      // we found subscriptions for fulljids, hence we need to remove the pubsub node once to clean this up
      this.logger.info("getPubSubDeviceSubs cleanup delta: ", delta);
      // this.xmpp.deleteNode(this.getUserJid(), "urn:xmpp:omemo:1:devices", (err, data) => {
        //  this.logger.info("getPubSubDeviceSubs deletenode: ", data, err);
        // });


        this.xmpp.updateNodeSubscriptions(this.getUserJid(), "urn:xmpp:omemo:1:devices", delta, (err, data) => {
          this.logger.info("getPubSubDeviceSubs updateResult: ", data, err);
        });

      }
  }

  public sendMarkReadSignalToSelf(conversationTarget: string) {
    const signal = {
      type: "read",
      target: conversationTarget
    };
    this.sendSignalToMyself(signal);
  }

  public getLastInactiveTime() {
    return this.LastInactiveTime;
  }

  getOMEMODevicesForTarget(target, skipMam: boolean = false) {
    const response = new Subject<boolean>();
    this.xmpp.getItems(target, "urn:xmpp:omemo:1:devices", null, (err, res) => {
      if (!!res && res.pubsub?.retrieve?.item?.deviceList?.devices && (res.pubsub.retrieve.item.deviceList.devices.length > 0)) {
        this.databaseService.storeDevices(target, res.pubsub.retrieve.item.deviceList.devices).subscribe(() => {
          if (!skipMam) {
            const lastXmppDisconnectAt = !!localStorage.getItem("lastXmppDisconnectAt") ? parseInt(localStorage.getItem("lastXmppDisconnectAt")) - 1800 : Math.floor(Date.now() / 1000) - 3600;
            const startDate = new Date(lastXmppDisconnectAt * 1000).toISOString();
            this.store.select(state => getConversationById(state, target))
              .pipe(take(1))
              .subscribe(conv => {
                this.logger.info("[xmppservice][getOmemoDevicesForTarget] => requestMAM?: ", target, conv);
                if (!!conv?.encrypted) {
                  this.databaseService.getLastOmemoActiveTS(target).subscribe(lts => {
                    if (lts > 0) {
                      const ltsDate = new Date(lts).toISOString();
                      this.requestOmemoMam(target, ltsDate);
                    } else {
                      this.requestOmemoMam(target, startDate);
                    }
                  });
                }
              });
          }
          response.next(true);
        });
      } else {
        response.next(true);
      }
    });

    return response.asObservable();
  }


  requestOmemoMam(target: string, start: string, after?: string) {
    if (!start && !after) {
      return;
    }
    let mamRequest = {
      with: target,
      rsm: {
        max: 10
      }
    };
    if (!!after) {
      mamRequest["after"] = after;
    } else {
      if (!!start) {
        mamRequest["start"] = start;
      }
    }
    this.xmpp.searchHistory(mamRequest, (err, res) => {
      this.logger.info("GETMAMAFTERPUBSUB ", target, start, after, res);
      if (!!res) {
        const complete = res.mamResult?.complete;
        if (!!res.mamResult && complete) {
          this.logger.info("[conversationRepository][mamAfterPubSub] finished for : ", target);
        } else {
          const mamItems = !!res.mamResult.items ? res.mamResult.items : [];
          if (mamItems.length > 0) {
            const lastItemIndex = mamItems.length - 1;
            const lastItem = mamItems[lastItemIndex];
            this.logger.info("[conversationRepository][mamAfterPubSub] lastMamItem : ", lastItem);
            if (!!lastItem && !!lastItem.forwarded && !!lastItem.forwarded.delay && !!lastItem.forwarded.delay.stamp) {
              const lastItemStamp = lastItem.forwarded.delay.stamp;
              this.logger.info("[conversationRepository][mamAfterPubSub] lastMamItem stamp : ", lastItemStamp, typeof lastItemStamp);
              // toDo: check if this needs to be delayed
              this.requestOmemoMam(target, lastItemStamp);
            }
          }
        }
      }
    });

  }

  requestResend(msg) {
    const target = (this.xmpp.jid.bare === msg.from.bare) ? msg.to.bare : msg.from.bare;
    const signal = {
      type: "requestresend",
      target: target,
      data: msg.id,
    };
    this.sendSignalToTarget(signal);
  }


  sendPresenceProbe(target: string) {
    this.xmpp.sendPresence({
      to: target,
      type: "probe"
    });
  }

}



enum XmppConnectionStatus {Disconnected, Connecting, Connected, SessionStarted}
